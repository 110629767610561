import React from 'react';
import type { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { GoogleLogin } from 'react-google-login';
import SVG from 'react-inlinesvg';
import type { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';
import { DRAGONFRUIT_BLACK_05 } from '@applied/dragonfruit/src/colors/colors';
import Icon from '@applied/dragonfruit/src/components/Icon/Icon';
import Modal from '@applied/dragonfruit/src/components/Modal/Modal';
import TextField from '@applied/dragonfruit/src/components/TextField/TextField';
import ConditionalTooltip from '@applied/dragonfruit/src/components/Tooltip/ConditionalTooltip';
import { DragonFruitIcons } from '@applied/dragonfruit/src/icons/icons';
import { setCookie } from '@applied/utils/cookies';
import classNames from 'classnames';
import * as qs from 'query-string';

import './login.scss';

import Logo from './assets/logo_applied.svg';

import { LoginPageLoginMethod } from './@applied/proto/web_services/accounts/proto/authentication_pb';
import LoginPageBackgroundMobile from './assets/login_page_background_mobile.mp4';
import LoginPageBackground from './assets/login_page_background.mp4';
import { postData } from './postData';

// This ID needs to match the client ID we use to decode the Google
// token on the accounts app.
const GOOGLE_CLIENT_ID = '831541393360-qs68q85d62v6dlm0s1occou5p8gh9rka.apps.googleusercontent.com';
// Error type when the domain this page is server from is not
// registered on the Google console.
const GOOGLE_DOMAIN_ERROR = 'idpiframe_initialization_failed';
const GOOGLE_DISABLED_TOOLTIP = 'Contact Applied to enable Google SSO.';
const GENERIC_ERROR = 'There was a problem. Please try again.';
const SSO_LOGIN = 'Login with Custom SSO provider';
const SSO_REGISTER = 'Sign up with Custom SSO provider';

const LOGIN_MOBILE_WIDTH = 512;
const DEFAULT_LOCAL_ACCOUNTS_PORT = '10064';
export const LOCAL_ACCOUNTS_NOT_SUPPORTED_MESSAGE =
  'Not supported for local network authentication flow. Please contact your customer admin.';

function isLoginMethodAllowed(
  method: LoginPageLoginMethod,
  location: RouteComponentProps['location'],
  local_accounts_host: string,
): boolean {
  // Only applied login is allowed for local accounts flow
  if (local_accounts_host && method !== LoginPageLoginMethod.APPLIED) {
    return false;
  }
  const query = qs.parse(location.search);
  const loginMethods = query.login_method;
  if (typeof loginMethods === 'string') {
    return loginMethods === method.toString();
  }
  if (Array.isArray(loginMethods)) {
    return loginMethods.includes(method.toString());
  }

  // Login methods weren't specified or were misformatted, so allow all login methods.
  return true;
}

// Changing the values of this enum might break links to this page, as they're used as query
// params in the URL.
export enum LoginView {
  LOGIN = 'login',
  RESET_PASSWORD = 'resetPassword',
  REGISTER = 'register',
}

enum AccountsPath {
  LOGIN_LEGACY = 'login',
  RESET_PASSWORD = 'resetPasswordRequest',
  REGISTER_LEGACY = 'register',
  LOGIN = 'auth/login',
  REGISTER = 'auth/register',
  SSO = 'auth/login/sso',
}

// Needs to match the values in the account_utils module
// used by the accounts service.
export enum LoginMethod {
  GOOGLE = 'GOOGLE',
  SAML = 'SAML',
  OIDC = 'OIDC',
}

function loginViewFromString(view: string): LoginView | undefined {
  for (const [_key, value] of Object.entries(LoginView)) {
    if (view.toUpperCase() === value.toUpperCase()) {
      return value;
    }
  }

  return undefined;
}

function getAccountsUrl(path: string): string {
  if (path.startsWith('/')) {
    return `${__ACCOUNTS_URL__}${path}`;
  }
  return `${__ACCOUNTS_URL__}/${path}`;
}

type SSOLoginMethod = LoginMethod.SAML | LoginMethod.OIDC;

type LoginRegisterProps = RouteComponentProps;

interface LoginRegisterState {
  pageWidth: number;
  errorMessage: string;
  successMessage: string;
  loginEmail: string;
  loginPassword: string;
  rFirst: string;
  rLast: string;
  rEmail: string;
  rPass: string;
  rConfirmPass: string;
  loginRequested: boolean;
  registerRequested: boolean;
  resetPasswordRequested: boolean;
  googleButtonDisabled: boolean;
  showSSOModal: boolean;
  emailSSO: string;
  home_page_banner: string;
  local_accounts_host: string;
  local_accounts_port: string;
}

// eslint-disable-next-line functional/no-class
class LoginRegister extends React.PureComponent<LoginRegisterProps, LoginRegisterState> {
  state: LoginRegisterState = {
    pageWidth: undefined! /* Fixme: http://tiny.cc/r4g9vz */,
    errorMessage: '',
    successMessage: '',
    loginEmail: '',
    loginPassword: '',
    rFirst: '',
    rLast: '',
    rEmail: '',
    rPass: '',
    rConfirmPass: '',
    loginRequested: false,
    registerRequested: false,
    resetPasswordRequested: false,
    googleButtonDisabled: false,
    showSSOModal: false,
    emailSSO: '',
    home_page_banner: '',
    local_accounts_host: '',
    local_accounts_port: '',
  };

  componentDidMount(): void {
    const { location } = this.props;

    // Redirected here from the SSO login/register flow, try to login/register
    // to Applied immediately.
    const parsed = qs.parse(location.search);
    if (parsed.saml !== undefined && parsed.email !== undefined) {
      // The login/register values need to match the values of LoginView above
      if (parsed.login !== undefined) {
        this.handleSSOLogin(parsed.email as string, LoginMethod.SAML, parsed.id as string);
      }
      if (parsed.register !== undefined) {
        this.handleSSORegister(LoginMethod.SAML, parsed.id as string);
      }
    }
    if (parsed.oidc !== undefined && parsed.email !== undefined) {
      // The login/register values need to match the values of LoginView above
      if (parsed.login !== undefined) {
        this.handleSSOLogin(parsed.email as string, LoginMethod.OIDC, parsed.id as string);
      }
      if (parsed.register !== undefined) {
        this.handleSSORegister(LoginMethod.OIDC, parsed.id as string);
      }
    }
    if (parsed.home_page_banner !== undefined) {
      this.setState({ home_page_banner: parsed.home_page_banner as string });
    }
    if (parsed.local_accounts_host !== undefined) {
      this.setState({ local_accounts_host: parsed.local_accounts_host as string });
    }
    if (parsed.local_accounts_port !== undefined) {
      this.setState({ local_accounts_port: parsed.local_accounts_port as string });
    } else {
      this.setState({ local_accounts_port: DEFAULT_LOCAL_ACCOUNTS_PORT });
    }
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.onResize);
  }

  private updateCurrentViewQueryParam(currentView: LoginView): void {
    const { history } = this.props;
    const search = qs.parse(history.location.search);
    search.view = currentView;
    history.push({
      pathname: history.location.pathname,
      search: qs.stringify(search),
    });
  }

  private getCurrentView(): LoginView {
    const { location } = this.props;
    const search = qs.parse(location.search);
    if (search.view) {
      return loginViewFromString(search.view.toString()) ?? LoginView.LOGIN;
    }

    return LoginView.LOGIN;
  }

  private onResize = (): void => {
    this.setState({ pageWidth: window.innerWidth });
  };

  changeView = (event: React.MouseEvent<HTMLSpanElement>): void => {
    const { local_accounts_host } = this.state;
    if (local_accounts_host) {
      this.setState({ errorMessage: LOCAL_ACCOUNTS_NOT_SUPPORTED_MESSAGE, successMessage: '' });
      return;
    }
    const desiredView = loginViewFromString((event.target as HTMLSpanElement).dataset.value ?? '');
    if (desiredView && Object.values(LoginView).includes(desiredView)) {
      this.setState({ errorMessage: '' });
      this.updateCurrentViewQueryParam(desiredView);
    }
  };

  handleRegister = (event: React.MouseEvent): void => {
    event.preventDefault();
    const { rFirst, rLast, rEmail, rPass, rConfirmPass } = this.state;
    this.setState({ registerRequested: true });
    postData(getAccountsUrl(AccountsPath.REGISTER_LEGACY), {
      first_name: rFirst,
      last_name: rLast,
      email: rEmail,
      password: rPass,
      confirm_password: rConfirmPass,
    })
      .then((data) => {
        this.setState({ registerRequested: false });

        if ('error' in data) {
          this.setState({ successMessage: '', errorMessage: data.error });
          return;
        }

        if ('success' in data) {
          this.setState({ errorMessage: '' });
          this.setState({ successMessage: data.success });
          this.setState({ loginEmail: rEmail });
          this.updateCurrentViewQueryParam(LoginView.LOGIN);
        }
      })
      .catch(() => {
        this.setState({
          registerRequested: false,
          errorMessage: GENERIC_ERROR,
        });
      });
  };

  private isAbsoluteRedirect(redirectLocation: string): boolean {
    return new URL(document.baseURI).origin !== new URL(redirectLocation, document.baseURI).origin;
  }

  handleLoginSuccess = (serverResponse: any): void => {
    const { location } = this.props;

    let redirectURL;
    if ('auth' in serverResponse) {
      this.setState({ errorMessage: '' });
      setCookie('auth_token', serverResponse.auth, 14);

      const parsed = qs.parse(location.search);
      const isAbsoluteRedirect =
        parsed.redirect &&
        typeof parsed.redirect === 'string' &&
        this.isAbsoluteRedirect(parsed.redirect);

      if (isAbsoluteRedirect) {
        // redirect to absolute location in URL if not relative to current host
        redirectURL = parsed.redirect;
      } else if (parsed.redirect) {
        // redirect to relative location in URL if relative to current host
        redirectURL = window.location.origin + parsed.redirect;
      } else {
        redirectURL = window.location.origin;
      }

      redirectURL += window.location.hash;
    }
    if (redirectURL) {
      window.location.assign(redirectURL as any);
    }
  };

  handleLogin = (event: React.MouseEvent): void => {
    event.preventDefault();
    const { loginEmail, loginPassword, local_accounts_host, local_accounts_port } = this.state;

    this.setState({ loginRequested: true });
    let accounts_url = getAccountsUrl(AccountsPath.LOGIN_LEGACY);

    // Local network authentication flow (e.g. for LDAP customers)
    if (local_accounts_host && local_accounts_port) {
      accounts_url = `${local_accounts_host}:${local_accounts_port}/authenticate`;
    }

    postData(accounts_url, {
      email: loginEmail,
      password: loginPassword,
    })
      .then((serverResponse) => {
        this.setState({ loginRequested: false });
        if ('error' in serverResponse) {
          this.setState({ errorMessage: serverResponse.error, successMessage: '' });
          return;
        }
        this.handleLoginSuccess(serverResponse);
      })
      .catch(() => {
        this.setState({
          loginRequested: false,
          errorMessage: GENERIC_ERROR,
        });
      });
  };

  handleResetPassword = (event: React.MouseEvent): void => {
    event.preventDefault();
    const { loginEmail } = this.state;
    this.setState({ resetPasswordRequested: true });
    postData(getAccountsUrl(AccountsPath.RESET_PASSWORD), { email: loginEmail })
      .then((data) => {
        this.setState({ resetPasswordRequested: false });
        if ('error' in data) {
          this.setState({ errorMessage: data.error, successMessage: '' });
        }

        if ('success' in data) {
          this.setState({ successMessage: data.success });
          this.updateCurrentViewQueryParam(LoginView.LOGIN);
        }
      })
      .catch(() => {
        this.setState({
          resetPasswordRequested: false,
          errorMessage: GENERIC_ERROR,
        });
      });
  };

  clickSupport = (): any => {
    const { local_accounts_host } = this.state;
    if (local_accounts_host) {
      this.setState({ errorMessage: LOCAL_ACCOUNTS_NOT_SUPPORTED_MESSAGE, successMessage: '' });
      return;
    }
    window.open('mailto:support@applied.co');
  };

  responseGoogleLoginSuccess = (response: GoogleLoginResponse): void => {
    this.setState({ loginRequested: true });
    const authHeader = { Authorization: `Bearer ${response.tokenId}` };

    postData(
      getAccountsUrl(AccountsPath.LOGIN),
      {
        email: response.profileObj.email,
        login_method: LoginMethod.GOOGLE,
      },
      authHeader,
    )
      .then((serverResponse) => {
        this.setState({ loginRequested: false });

        if ('error' in serverResponse) {
          this.setState({ errorMessage: serverResponse.error, successMessage: '' });
          return;
        }

        this.handleLoginSuccess(serverResponse);
      })
      .catch(() => {
        this.setState({
          resetPasswordRequested: false,
          errorMessage: GENERIC_ERROR,
        });
      });
  };

  responseGoogleLoginFailure = (error: any): void => {
    // This error happens when the domain is not registered
    // with Google
    if (error.error === GOOGLE_DOMAIN_ERROR) {
      this.setState({
        googleButtonDisabled: true,
      });
      return;
    }
    console.error(error);
    this.setState({
      loginRequested: false,
      errorMessage: 'Google login failed.',
    });
  };

  responseGoogleLoadScriptFailure = (): void => {
    this.setState({
      googleButtonDisabled: true,
    });
  };

  responseGoogleRegisterSuccess = (response: GoogleLoginResponse): void => {
    this.setState({ registerRequested: true });
    const authHeader = { Authorization: `Bearer ${response.tokenId}` };

    postData(
      getAccountsUrl(AccountsPath.REGISTER),
      {
        register_method: LoginMethod.GOOGLE,
      },
      authHeader,
    )
      .then((serverResponse) => {
        this.setState({ registerRequested: false });

        if ('error' in serverResponse) {
          this.setState({ errorMessage: serverResponse.error, successMessage: '' });
          return;
        }

        if ('success' in serverResponse) {
          this.setState({
            errorMessage: '',
            successMessage: serverResponse.success,
          });
          this.updateCurrentViewQueryParam(LoginView.LOGIN);
        }
      })
      .catch(() => {
        this.setState({
          resetPasswordRequested: false,
          errorMessage: GENERIC_ERROR,
        });
      });
  };

  responseGoogleRegisterFailure = (error: any): void => {
    // This error happens when the domain is note registered
    // with Google
    if (error.error === GOOGLE_DOMAIN_ERROR) {
      this.setState({
        googleButtonDisabled: true,
      });
      return;
    }
    // eslint-disable-next-line no-console
    console.log(error);
    this.setState({
      loginRequested: false,
      errorMessage: 'Google register failed.',
    });
  };

  private updateValue(
    field: keyof LoginRegisterState,
    ev: React.ChangeEvent<HTMLInputElement>,
  ): void {
    const newState = { [field]: ev.target.value };
    this.setState(newState as any);
  }

  initiateSSO = (): void => {
    const { emailSSO } = this.state;

    this.toggleSSOModal(false);

    const parsed = qs.parse(location.search);
    postData(
      getAccountsUrl(AccountsPath.SSO),
      {
        email: emailSSO,
        source: this.getCurrentView(),
        redirect: parsed.redirect,
      },
      undefined /* headers */,
      true /* withCredentials */,
    )
      .then((serverResponse) => {
        if ('error' in serverResponse) {
          this.setState({ errorMessage: serverResponse.error, successMessage: '' });
          return;
        }
        window.location = serverResponse.sso_url;
      })
      .catch(() => {
        this.setState({
          errorMessage: GENERIC_ERROR,
        });
      });
  };

  handleSSOLogin = (
    email: string,
    sso_method: SSOLoginMethod,
    request_id: string | undefined = undefined,
  ): void => {
    this.setState({ loginRequested: true });

    postData(
      getAccountsUrl(AccountsPath.LOGIN),
      {
        email,
        login_method: sso_method,
        id: request_id,
      },
      undefined /* headers */,
      true /* withCredentials */,
    )
      .then((serverResponse) => {
        this.setState({ loginRequested: false });
        if ('error' in serverResponse) {
          this.setState({ errorMessage: serverResponse.error, successMessage: '' });
          return;
        }
        this.handleLoginSuccess(serverResponse);
      })
      .catch(() => {
        this.setState({
          loginRequested: false,
          errorMessage: GENERIC_ERROR,
        });
      });
  };

  handleSSORegister = (
    sso_method: SSOLoginMethod,
    request_id: string | undefined = undefined,
  ): void => {
    this.setState({ registerRequested: true });

    postData(
      getAccountsUrl(AccountsPath.REGISTER),
      {
        register_method: sso_method,
        id: request_id,
      },
      undefined /* headers */,
      true /* withCredentials */,
    )
      .then((serverResponse) => {
        this.setState({ registerRequested: false });

        if ('error' in serverResponse) {
          this.setState({ errorMessage: serverResponse.error, successMessage: '' });
          return;
        }

        if ('success' in serverResponse) {
          this.setState({
            errorMessage: '',
            successMessage: serverResponse.success,
          });
          this.updateCurrentViewQueryParam(LoginView.LOGIN);
        }
      })
      .catch(() => {
        this.setState({
          resetPasswordRequested: false,
          errorMessage: GENERIC_ERROR,
        });
      });
  };

  private createGoogleLoginButton(
    text: string,
    onSuccess: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void,
    onFailure: (response: any) => void,
  ): JSX.Element {
    const { googleButtonDisabled } = this.state;
    return (
      <div className="google-button-wrapper">
        <ConditionalTooltip
          tooltip={GOOGLE_DISABLED_TOOLTIP}
          showTooltip={googleButtonDisabled}
          shouldContentWrapperFillContainer
        >
          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            buttonText={text}
            className="google-login-button"
            onSuccess={onSuccess}
            onFailure={onFailure}
            onScriptLoadFailure={this.responseGoogleLoadScriptFailure}
            disabled={googleButtonDisabled}
            tag="span"
          />
        </ConditionalTooltip>
      </div>
    );
  }

  private createSSOButton(
    text: string,
    initiateSSO: () => void,
    toggleModal: (showOrHide: boolean) => void,
    updateEmail: (value: string) => void,
    primaryButtonText: string,
    email?: string,
  ): JSX.Element {
    const { showSSOModal } = this.state;
    return (
      <div className="sso-login-button" onClick={(): void => toggleModal(true)}>
        <Icon height={20} src={DragonFruitIcons.LOGIN} color={DRAGONFRUIT_BLACK_05} />
        <span data-value="ssoLogin">{text}</span>
        {showSSOModal && (
          <Modal
            onClose={(): void => toggleModal(false)}
            title="SSO Login"
            primaryButton={{ text: primaryButtonText, onClick: initiateSSO }}
            secondaryButton={{ text: 'Cancel' }}
          >
            <TextField
              caption="Enter your email"
              value={email ?? ''}
              onChange={updateEmail}
              focusOnMount
              height="medium"
              shouldFillContainer
            />
          </Modal>
        )}
      </div>
    );
  }

  toggleSSOModal = (openOrHide: boolean): void => {
    const { loginEmail, rEmail } = this.state;
    const currentView = this.getCurrentView();
    this.setState({
      showSSOModal: openOrHide,
      emailSSO: currentView === LoginView.LOGIN ? loginEmail : rEmail,
    });
  };

  updateSSOEmail = (value: string): void => {
    this.setState({
      emailSSO: value,
    });
  };

  private createLoginForm(): JSX.Element {
    const { location } = this.props;
    const { loginRequested, emailSSO, home_page_banner, local_accounts_host } = this.state;

    const appliedLoginAllowed = isLoginMethodAllowed(
      LoginPageLoginMethod.APPLIED,
      location,
      local_accounts_host,
    );
    const googleAllowed = isLoginMethodAllowed(
      LoginPageLoginMethod.GOOGLE,
      location,
      local_accounts_host,
    );
    const ssoAllowed = isLoginMethodAllowed(
      LoginPageLoginMethod.CUSTOM_SSO,
      location,
      local_accounts_host,
    );

    return (
      <form>
        {appliedLoginAllowed && (
          <>
            <input
              type="text"
              placeholder="Email"
              onChange={(ev): void => this.updateValue('loginEmail', ev)}
            />
            <input
              type="password"
              placeholder="Password"
              onChange={(ev): void => this.updateValue('loginPassword', ev)}
            />
            <input
              type="submit"
              value="Login"
              disabled={loginRequested}
              onClick={this.handleLogin}
            />
          </>
        )}
        {appliedLoginAllowed && (googleAllowed || ssoAllowed) && <div className="subtext">or</div>}
        {googleAllowed &&
          this.createGoogleLoginButton(
            'Login with Google',
            this.responseGoogleLoginSuccess,
            this.responseGoogleLoginFailure,
          )}
        {ssoAllowed &&
          this.createSSOButton(
            SSO_LOGIN,
            this.initiateSSO,
            this.toggleSSOModal,
            this.updateSSOEmail,
            'Login',
            emailSSO,
          )}
        <div className="subtext large-margin">
          <span className="link" data-value={LoginView.REGISTER} onClick={this.changeView}>
            Sign Up
          </span>
          <span> • </span>
          <span className="link" data-value={LoginView.RESET_PASSWORD} onClick={this.changeView}>
            Forgot Password
          </span>
        </div>
        <div className="subtext link" onClick={this.clickSupport}>
          support@applied.co
        </div>
        {home_page_banner && <div className="home-page-banner"> {home_page_banner} </div>}
      </form>
    );
  }

  private createRegisterForm(): JSX.Element {
    const { location } = this.props;
    const { registerRequested, emailSSO, local_accounts_host } = this.state;

    const appliedLoginAllowed = isLoginMethodAllowed(
      LoginPageLoginMethod.APPLIED,
      location,
      local_accounts_host,
    );
    const googleAllowed = isLoginMethodAllowed(
      LoginPageLoginMethod.GOOGLE,
      location,
      local_accounts_host,
    );
    const ssoAllowed = isLoginMethodAllowed(
      LoginPageLoginMethod.CUSTOM_SSO,
      location,
      local_accounts_host,
    );

    return (
      <form>
        {appliedLoginAllowed && (
          <>
            <input
              type="text"
              placeholder="(Optional) First Name"
              onChange={(ev): void => this.updateValue('rFirst', ev)}
            />
            <input
              type="text"
              placeholder="(Optional) Last Name"
              onChange={(ev): void => this.updateValue('rLast', ev)}
            />
            <input
              type="text"
              placeholder="Company Email"
              onChange={(ev): void => this.updateValue('rEmail', ev)}
            />
            <input
              type="password"
              placeholder="Password"
              onChange={(ev): void => this.updateValue('rPass', ev)}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              onChange={(ev): void => this.updateValue('rConfirmPass', ev)}
            />
            <input
              type="submit"
              value="Sign Up"
              disabled={registerRequested}
              onClick={this.handleRegister}
            />
          </>
        )}
        {appliedLoginAllowed && (googleAllowed || ssoAllowed) && <div className="subtext">or</div>}
        {googleAllowed &&
          this.createGoogleLoginButton(
            'Sign up with Google',
            this.responseGoogleRegisterSuccess,
            this.responseGoogleRegisterFailure,
          )}
        {ssoAllowed &&
          this.createSSOButton(
            SSO_REGISTER,
            this.initiateSSO,
            this.toggleSSOModal,
            this.updateSSOEmail,
            'Sign up',
            emailSSO,
          )}
        <div className="subtext large-margin">Already have an account?</div>
        <div className="subtext link" data-value={LoginView.LOGIN} onClick={this.changeView}>
          Login
        </div>
      </form>
    );
  }

  private createPasswordResetForm(): JSX.Element {
    const { resetPasswordRequested } = this.state;

    return (
      <form>
        <input
          type="text"
          placeholder="Email"
          onChange={(ev): void => this.updateValue('loginEmail', ev)}
        />
        <input
          type="submit"
          value="Send a Password Reset Email"
          disabled={resetPasswordRequested}
          onClick={this.handleResetPassword}
        />
        <div
          className="subtext large-margin link"
          data-value={LoginView.LOGIN}
          onClick={this.changeView}
        >
          Back to Login
        </div>
      </form>
    );
  }

  render(): JSX.Element {
    const { successMessage, loginRequested, registerRequested, resetPasswordRequested, pageWidth } =
      this.state;
    let { errorMessage } = this.state;

    const currentView = this.getCurrentView();

    if (loginRequested || resetPasswordRequested || registerRequested) {
      errorMessage = '';
    }

    return (
      <div className="login-container">
        <video
          autoPlay
          loop
          muted
          playsInline
          id="test-desktop-background"
          className={classNames('background', { hidden: pageWidth < LOGIN_MOBILE_WIDTH })}
        >
          <source src={LoginPageBackground} type="video/mp4" />
        </video>
        <video
          autoPlay
          loop
          muted
          playsInline
          id="test-mobile-background"
          className={classNames('background', { hidden: pageWidth >= LOGIN_MOBILE_WIDTH })}
        >
          <source src={LoginPageBackgroundMobile} type="video/mp4" />
        </video>
        <div className="login-form-container">
          <div className="login-form backdrop-blur">
            <SVG src={Logo} className="applied-logo" />
            {errorMessage ? (
              <div className="error-message">{errorMessage}</div>
            ) : (
              <div className="success-message">{successMessage}</div>
            )}
            {currentView === LoginView.LOGIN && this.createLoginForm()}
            {currentView === LoginView.REGISTER && this.createRegisterForm()}
            {currentView === LoginView.RESET_PASSWORD && this.createPasswordResetForm()}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter<LoginRegisterProps, typeof LoginRegister>(LoginRegister);

import { hexToRgbA } from './color_conversion';

// https://www.figma.com/file/VJjnW0snUdPH22QYX5oi3l/Dragonfruit-Design-System?node-id=3%3A2534

// dark theme foundational colors
export const DRAGONFRUIT_BLACK_01 = '#121212';
export const DRAGONFRUIT_BLACK_02 = '#1C1C1C';
export const DRAGONFRUIT_BLACK_03 = '#212121';
export const DRAGONFRUIT_BLACK_04 = '#262626';
export const DRAGONFRUIT_BLACK_05 = '#333333';

export const DRAGONFRUIT_WHITE_100 = '#FFFFFF';
export const DRAGONFRUIT_WHITE_80 = 'rgba(255, 255, 255, 0.8)';
export const DRAGONFRUIT_WHITE_50 = 'rgba(255, 255, 255, 0.5)';
export const DRAGONFRUIT_WHITE_50_HEX = '#FFFFFF7F';
export const DRAGONFRUIT_WHITE_25 = 'rgba(255, 255, 255, 0.25)';
export const DRAGONFRUIT_WHITE_16 = 'rgba(255, 255, 255, 0.16)';
export const DRAGONFRUIT_WHITE_5 = 'rgba(255, 255, 255, 0.05)';

// light theme foundational colors
export const DRAGONFRUIT_WHITE_01 = '#FAFAFA';
export const DRAGONFRUIT_WHITE_02 = '#FFFFFF';
export const DRAGONFRUIT_WHITE_03 = '#EDEDED';
export const DRAGONFRUIT_WHITE_04 = '#F8F8F8';
export const DRAGONFRUIT_WHITE_05 = '#F3F3F3';

export const DRAGONFRUIT_BLACK_100 = '#262626';
export const DRAGONFRUIT_BLACK_80 = '#515151';
export const DRAGONFRUIT_BLACK_50 = '#929292';
export const DRAGONFRUIT_BLACK_25 = '#C9C9C9';
export const DRAGONFRUIT_BLACK_16 = '#DCDCDC';
export const DRAGONFRUIT_BLACK_1 = '#E9E9E9';

// colors
export const DRAGONFRUIT_BLUE_1 = '#E0EEFE';
export const DRAGONFRUIT_BLUE_2 = '#C1DDFC';
export const DRAGONFRUIT_BLUE_3 = '#A1CCFB';
export const DRAGONFRUIT_BLUE_4 = '#82BBFA';
export const DRAGONFRUIT_BLUE_5 = '#63AAF8';
export const DRAGONFRUIT_BLUE_6 = '#4499F7';
export const DRAGONFRUIT_BLUE_7 = '#127EF5';
export const DRAGONFRUIT_BLUE_8 = '#0964C9';
export const DRAGONFRUIT_BLUE_9 = '#064B97';
export const DRAGONFRUIT_BLUE_10 = '#043265';

export const DRAGONFRUIT_GREEN_1 = '#E5F7EF';
export const DRAGONFRUIT_GREEN_2 = '#CBEEDF';
export const DRAGONFRUIT_GREEN_3 = '#B0E6CF';
export const DRAGONFRUIT_GREEN_4 = '#96DEBF';
export const DRAGONFRUIT_GREEN_5 = '#7CD5AF';
export const DRAGONFRUIT_GREEN_6 = '#62CD9F';
export const DRAGONFRUIT_GREEN_7 = '#3DC087';
export const DRAGONFRUIT_GREEN_8 = '#31996C';
export const DRAGONFRUIT_GREEN_9 = '#257351';
export const DRAGONFRUIT_GREEN_10 = '#184D36';

export const DRAGONFRUIT_PURPLE_1 = '#E4E0FF';
export const DRAGONFRUIT_PURPLE_2 = '#D3CCFF';
export const DRAGONFRUIT_PURPLE_3 = '#BDB2FF';
export const DRAGONFRUIT_PURPLE_4 = '#A799FF';
export const DRAGONFRUIT_PURPLE_5 = '#9180FF';
export const DRAGONFRUIT_PURPLE_6 = '#7B66FF';
export const DRAGONFRUIT_PURPLE_7 = '#6655D4';
export const DRAGONFRUIT_PURPLE_8 = '#5244AA';
export const DRAGONFRUIT_PURPLE_9 = '#3D3380';
export const DRAGONFRUIT_PURPLE_10 = '#292255';

export const DRAGONFRUIT_MAGENTA_1 = '#F6E4FB';
export const DRAGONFRUIT_MAGENTA_2 = '#EDCAF7';
export const DRAGONFRUIT_MAGENTA_3 = '#E4AFF3';
export const DRAGONFRUIT_MAGENTA_4 = '#DB94F0';
export const DRAGONFRUIT_MAGENTA_5 = '#D27AEC';
export const DRAGONFRUIT_MAGENTA_6 = '#C95FE8';
export const DRAGONFRUIT_MAGENTA_7 = '#B92FE1';
export const DRAGONFRUIT_MAGENTA_8 = '#9A1BBF';
export const DRAGONFRUIT_MAGENTA_9 = '#73158F';
export const DRAGONFRUIT_MAGENTA_10 = '#4D0E5F';

export const DRAGONFRUIT_RED_1 = '#FEE2DB';
export const DRAGONFRUIT_RED_2 = '#FDCFC3';
export const DRAGONFRUIT_RED_3 = '#FCB6A5';
export const DRAGONFRUIT_RED_4 = '#FB9E88';
export const DRAGONFRUIT_RED_5 = '#FA866A';
export const DRAGONFRUIT_RED_6 = '#F96E4C';
export const DRAGONFRUIT_RED_7 = '#F74418';
export const DRAGONFRUIT_RED_8 = '#D22F07';
export const DRAGONFRUIT_RED_9 = '#9D2305';
export const DRAGONFRUIT_RED_10 = '#691704';

export const DRAGONFRUIT_YELLOW_1 = '#FFF6DF';
export const DRAGONFRUIT_YELLOW_2 = '#FEECBF';
export const DRAGONFRUIT_YELLOW_3 = '#FEE39E';
export const DRAGONFRUIT_YELLOW_4 = '#FEDA7E';
export const DRAGONFRUIT_YELLOW_5 = '#FDD05E';
export const DRAGONFRUIT_YELLOW_6 = '#FDC73E';
export const DRAGONFRUIT_YELLOW_7 = '#FCB80A';
export const DRAGONFRUIT_YELLOW_8 = '#D09602';
export const DRAGONFRUIT_YELLOW_9 = '#9C7002';
export const DRAGONFRUIT_YELLOW_10 = '#684B01';

export const DRAGONFRUIT_ORANGE_1 = '#FFF0DE';
export const DRAGONFRUIT_ORANGE_2 = '#FFE1BD';
export const DRAGONFRUIT_ORANGE_3 = '#FFD19C';
export const DRAGONFRUIT_ORANGE_4 = '#FFC27B';
export const DRAGONFRUIT_ORANGE_5 = '#FFB35A';
export const DRAGONFRUIT_ORANGE_6 = '#FFA439';
export const DRAGONFRUIT_ORANGE_7 = '#FF8C05';
export const DRAGONFRUIT_ORANGE_8 = '#D07000';
export const DRAGONFRUIT_ORANGE_9 = '#9C5400';
export const DRAGONFRUIT_ORANGE_10 = '#683800';

export const DRAGONFRUIT_TURQUOISE_1 = '#DBF7F5';
export const DRAGONFRUIT_TURQUOISE_2 = '#C3F2EF';
export const DRAGONFRUIT_TURQUOISE_3 = '#A5EBE6';
export const DRAGONFRUIT_TURQUOISE_4 = '#87E4DE';
export const DRAGONFRUIT_TURQUOISE_5 = '#69DED6';
export const DRAGONFRUIT_TURQUOISE_6 = '#4BD7CE';
export const DRAGONFRUIT_TURQUOISE_7 = '#2CC6BC';
export const DRAGONFRUIT_TURQUOISE_8 = '#239E96';
export const DRAGONFRUIT_TURQUOISE_9 = '#1A7771';
export const DRAGONFRUIT_TURQUOISE_10 = '#124F4B';

export type DragonfruitColor =
  | 'black'
  | 'blue'
  | 'green'
  | 'purple'
  | 'magenta'
  | 'red'
  | 'yellow'
  | 'orange'
  | 'turquoise';

export const DRAGONFRUIT_PRIMARY_COLORS: Record<DragonfruitColor, string> = {
  black: DRAGONFRUIT_BLACK_05,
  blue: DRAGONFRUIT_BLUE_6,
  green: DRAGONFRUIT_GREEN_6,
  purple: DRAGONFRUIT_PURPLE_6,
  magenta: DRAGONFRUIT_MAGENTA_6,
  red: DRAGONFRUIT_RED_6,
  yellow: DRAGONFRUIT_YELLOW_6,
  orange: DRAGONFRUIT_ORANGE_6,
  turquoise: DRAGONFRUIT_TURQUOISE_6,
};

export const DRAGONFRUIT_COLORS: Record<Uppercase<Exclude<DragonfruitColor, 'black'>>, string[]> = {
  BLUE: [
    DRAGONFRUIT_BLUE_1,
    DRAGONFRUIT_BLUE_2,
    DRAGONFRUIT_BLUE_3,
    DRAGONFRUIT_BLUE_4,
    DRAGONFRUIT_BLUE_5,
    DRAGONFRUIT_BLUE_6,
    DRAGONFRUIT_BLUE_7,
    DRAGONFRUIT_BLUE_8,
    DRAGONFRUIT_BLUE_9,
    DRAGONFRUIT_BLUE_10,
  ],
  GREEN: [
    DRAGONFRUIT_GREEN_1,
    DRAGONFRUIT_GREEN_2,
    DRAGONFRUIT_GREEN_3,
    DRAGONFRUIT_GREEN_4,
    DRAGONFRUIT_GREEN_5,
    DRAGONFRUIT_GREEN_6,
    DRAGONFRUIT_GREEN_7,
    DRAGONFRUIT_GREEN_8,
    DRAGONFRUIT_GREEN_9,
    DRAGONFRUIT_GREEN_10,
  ],
  PURPLE: [
    DRAGONFRUIT_PURPLE_1,
    DRAGONFRUIT_PURPLE_2,
    DRAGONFRUIT_PURPLE_3,
    DRAGONFRUIT_PURPLE_4,
    DRAGONFRUIT_PURPLE_5,
    DRAGONFRUIT_PURPLE_6,
    DRAGONFRUIT_PURPLE_7,
    DRAGONFRUIT_PURPLE_8,
    DRAGONFRUIT_PURPLE_9,
    DRAGONFRUIT_PURPLE_10,
  ],
  MAGENTA: [
    DRAGONFRUIT_MAGENTA_1,
    DRAGONFRUIT_MAGENTA_2,
    DRAGONFRUIT_MAGENTA_3,
    DRAGONFRUIT_MAGENTA_4,
    DRAGONFRUIT_MAGENTA_5,
    DRAGONFRUIT_MAGENTA_6,
    DRAGONFRUIT_MAGENTA_7,
    DRAGONFRUIT_MAGENTA_8,
    DRAGONFRUIT_MAGENTA_9,
    DRAGONFRUIT_MAGENTA_10,
  ],
  RED: [
    DRAGONFRUIT_RED_1,
    DRAGONFRUIT_RED_2,
    DRAGONFRUIT_RED_3,
    DRAGONFRUIT_RED_4,
    DRAGONFRUIT_RED_5,
    DRAGONFRUIT_RED_6,
    DRAGONFRUIT_RED_7,
    DRAGONFRUIT_RED_8,
    DRAGONFRUIT_RED_9,
    DRAGONFRUIT_RED_10,
  ],
  YELLOW: [
    DRAGONFRUIT_YELLOW_1,
    DRAGONFRUIT_YELLOW_2,
    DRAGONFRUIT_YELLOW_3,
    DRAGONFRUIT_YELLOW_4,
    DRAGONFRUIT_YELLOW_5,
    DRAGONFRUIT_YELLOW_6,
    DRAGONFRUIT_YELLOW_7,
    DRAGONFRUIT_YELLOW_8,
    DRAGONFRUIT_YELLOW_9,
    DRAGONFRUIT_YELLOW_10,
  ],
  ORANGE: [
    DRAGONFRUIT_ORANGE_1,
    DRAGONFRUIT_ORANGE_2,
    DRAGONFRUIT_ORANGE_3,
    DRAGONFRUIT_ORANGE_4,
    DRAGONFRUIT_ORANGE_5,
    DRAGONFRUIT_ORANGE_6,
    DRAGONFRUIT_ORANGE_7,
    DRAGONFRUIT_ORANGE_8,
    DRAGONFRUIT_ORANGE_9,
    DRAGONFRUIT_ORANGE_10,
  ],
  TURQUOISE: [
    DRAGONFRUIT_TURQUOISE_1,
    DRAGONFRUIT_TURQUOISE_2,
    DRAGONFRUIT_TURQUOISE_3,
    DRAGONFRUIT_TURQUOISE_4,
    DRAGONFRUIT_TURQUOISE_5,
    DRAGONFRUIT_TURQUOISE_6,
    DRAGONFRUIT_TURQUOISE_7,
    DRAGONFRUIT_TURQUOISE_8,
    DRAGONFRUIT_TURQUOISE_9,
    DRAGONFRUIT_TURQUOISE_10,
  ],
};
Object.freeze(DRAGONFRUIT_COLORS);

export const DragonfruitBackgrounds = {
  BLUE: hexToRgbA(DRAGONFRUIT_BLUE_6, 0.2),
  GREEN: hexToRgbA(DRAGONFRUIT_BLUE_6, 0.2),
  PURPLE: hexToRgbA(DRAGONFRUIT_BLUE_6, 0.2),
  MAGENTA: hexToRgbA(DRAGONFRUIT_BLUE_6, 0.2),
  RED: hexToRgbA(DRAGONFRUIT_BLUE_6, 0.2),
  YELLOW: hexToRgbA(DRAGONFRUIT_BLUE_6, 0.2),
  ORANGE: hexToRgbA(DRAGONFRUIT_BLUE_6, 0.2),
  TURQUOISE: hexToRgbA(DRAGONFRUIT_BLUE_6, 0.2),
};

export enum Appearance {
  DEFAULT,
  HIGHLIGHT,
}

export const HIGHLIGHT_COLOR = DRAGONFRUIT_YELLOW_6;

import React from 'react';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';

import './icon.scss';

export interface IconProps {
  height: number;
  src?: string;
  className?: string;
  color?: string;
  width?: number;
}

const Icon: React.FC<IconProps> = (props: IconProps) => {
  const { height, src, color, className, width } = props;
  return (
    <SVG
      className={classNames('dragon-fruit-icon', className)}
      cacheGetRequests
      style={{
        height: height,
        minWidth: height,
        fill: color,
        width: width,
      }}
      src={src ?? ''}
    />
  );
};

export default Icon;

import type { RouteComponentProps } from 'react-router';
import * as qs from 'query-string';

// Scheme component, e.g. "http://", "https://", "ftp://".
const URL_SCHEME = '[A-Za-z]+:\\/\\/';
// Top-level domain, e.g. ".com", ".org". This must be 2-6 characters long.
const URL_TOP_LEVEL_DOMAIN = '\\.[a-z]{2,6}';
// URL full domain name, e.g. "www.google.com", "applied.co".
// This must be 2-256 characters and must start and end with an alphanumeric character.
const URL_DOMAIN_NAME =
  '[a-zA-Z\\d][a-zA-Z\\d_@:%\\.\\+~#=\\-]{0,254}[a-zA-Z\\d]' + URL_TOP_LEVEL_DOMAIN;
// This regex includes the optional path, query, and fragment, e.g. "simian/results", "?key=value#fragment".
// However, we err on the side of being more generic, so this also allows paths such as "/&/./-///_=".
const URL_AFTER_DOMAIN = '[a-zA-Z\\d\\-@:%_\\+\\.~#\\?&/=]*';
// Adapted https://regexr.com/39nr7 but updated to match generic URL patterns.
// Combines all the components for the overall URL checker regex. This regex restricts to
// strings that do not have any text before or after the matched URL.
const VALID_URL_GENERIC_REGEX =
  '^(' + URL_SCHEME + ')?' + URL_DOMAIN_NAME + '(\\/|:\\d+\\/?|$)' + URL_AFTER_DOMAIN + '$';

/**
 * Appends a query term with the given name and value to the end of the current
 * url.
 *
 * If the value is empty, the termName will be removed from the query string.
 */

export function appendOrReplaceQueryString(
  termName: string,
  value: string,
  history: RouteComponentProps['history'],
): void {
  const parsed = history.location && qs.parse(history.location.search);
  if (!value) {
    delete parsed[termName];
  } else {
    parsed[termName] = value;
  }
  let path = history.location.pathname;
  if (Object.keys(parsed).length > 0) {
    path += `?${qs.stringify(parsed)}`;
  }

  history.replace(path);
}

export function changePathMaintainQueryString(path: string): string {
  const parsed = window.location && qs.parse(window.location.search);
  if (Object.keys(parsed).length > 0) {
    path += `?${qs.stringify(parsed)}`;
  }
  return path;
}

/**
 * This URL validator uses a custom-created regex to validate URLs.
 * See the unit tests for examples of valid and invalid URLs.
 */
export function isValidUrl(url: string): boolean {
  if (!url) {
    return false;
  }
  return !!url.match(new RegExp(VALID_URL_GENERIC_REGEX));
}

/**
 * This URL validator uses the URL library to parse the URL,
 * and thus requires a scheme (identified by a colon) in the URL.
 *
 * Valid:
 *    - https://example.org/path?query=arg#fragment
 *    - https://example.org
 *    - asdf://asdf
 *    - a:
 * Invalid:
 *    - www.google.com/path?query=arg#fragment
 *    - www.google.com
 *    - asdf
 *    - :asdf
 */
export function isValidUrlLegacy(url: string): boolean {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}

export function mergeAndReplacePath(
  oldSearch: string,
  newSearch: string,
  path: string,
  history: RouteComponentProps['history'],
): void {
  const oldParsed = qs.parse(oldSearch);
  const newParsed = qs.parse(newSearch);
  const mergedParams = {
    ...oldParsed,
    ...newParsed,
  };
  // Only add query string if there are parameters.
  if (Object.keys(mergedParams).length > 0) {
    path += `?${qs.stringify(mergedParams)}`;
  }
  history.replace(path);
}

export function bulkAppendOrReplaceQueryString(
  nameValueMap: Map<string, string>,
  history: RouteComponentProps['history'],
): void {
  nameValueMap.forEach((value, key) => {
    appendOrReplaceQueryString(key, value, history);
  });
}

// Reference: https://stackoverflow.com/a/55142565
export function pathJoin(parts: string[], sep?: string): string {
  const separator = sep || '/';
  parts = parts.map((part, index) => {
    if (index) {
      part = part.replace(new RegExp('^' + separator), '');
    }
    if (index !== parts.length - 1) {
      part = part.replace(new RegExp(separator + '$'), '');
    }
    return part;
  });
  return parts.join(separator);
}

export function isRelativeUrl(url: string): boolean {
  return url.startsWith('/');
}

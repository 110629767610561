import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import LoginRegister from './LoginRegister';

export const App = (): JSX.Element => (
  <BrowserRouter>
    <Switch>
      <Route component={LoginRegister} />
    </Switch>
  </BrowserRouter>
);

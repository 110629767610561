export async function postData(
  url: string,
  body: object,
  headers?: Record<string, string>,
  withCredentials?: boolean,
): Promise<any> {
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    body: JSON.stringify(body),
    credentials: withCredentials ? 'include' : undefined,
  });
  return response.json();
}

import React from 'react';
import classNames from 'classnames';

import './tooltip.scss';

import type { InternalCursorTooltipPlacementProps } from './InternalCursorTooltipPlacement';
import InternalCursorTooltipPlacement, {
  TOOLTIP_MAX_WIDTH,
} from './InternalCursorTooltipPlacement';
import type { InternalFixedTooltipPlacementProps } from './InternalFixedTooltipPlacement';
import InternalFixedTooltipPlacement from './InternalFixedTooltipPlacement';

interface BaseTooltipProps {
  tooltip: string | JSX.Element;
  placementType?: TooltipPlacementType;
  /** When defined, the tooltip will stay open if the mouse is over the tooltip. */
  secondaryText?: string;
  className?: string;
  maxWidth?: number;
  children?: React.ReactNode;
}

export type TooltipPlacementType = 'cursor' | 'fixed';

export type TooltipProps = BaseTooltipProps &
  (
    | ({ placementType?: 'cursor' } & Omit<
        InternalCursorTooltipPlacementProps,
        'tooltip' | 'maxWidth'
      >)
    | ({ placementType: 'fixed' } & Omit<
        InternalFixedTooltipPlacementProps,
        'tooltip' | 'maxWidth'
      >)
  );

const Tooltip: React.FC<TooltipProps> = (props) => {
  const {
    tooltip,
    secondaryText,
    children,
    placementType,
    className,
    maxWidth = TOOLTIP_MAX_WIDTH,
  } = props;

  const tooltipContent = (
    <div
      className={classNames('dragonfruit-tooltip', className)}
      style={{ maxWidth }}
      data-testid="dragonfruit-tooltip"
    >
      {typeof tooltip === 'string' ? (
        <>
          <span className="primary-text text-style-title-caption">{tooltip}</span>
          {secondaryText && (
            <span className="secondary-text text-style-title-caption">{secondaryText}</span>
          )}
        </>
      ) : (
        tooltip
      )}
    </div>
  );

  return placementType === 'fixed' ? (
    <InternalFixedTooltipPlacement {...props} maxWidth={maxWidth} tooltip={tooltipContent}>
      {children}
    </InternalFixedTooltipPlacement>
  ) : (
    <InternalCursorTooltipPlacement {...props} maxWidth={maxWidth} tooltip={tooltipContent}>
      {children}
    </InternalCursorTooltipPlacement>
  );
};

export default Tooltip;

import React, { useRef, useState } from 'react';
import { Portal } from 'react-portal';
import classNames from 'classnames';

import { DRAGONFRUIT_PORTAL_ROOT_ID } from '../../portal';

export type TooltipSide = 'top' | 'bottom' | 'left' | 'right';
export type TooltipAlignment = 'start' | 'center' | 'end';

const PADDING = 2;
/** Number of milliseconds to wait on-mouse-leave before closing interactive tooltips. */
const MOUSE_LEAVE_DELAY_MS = 400;

function computePositioning(
  side: TooltipSide,
  alignment: TooltipAlignment,
  boundingBox: DOMRect,
  maxWidth: number,
): React.CSSProperties {
  let style: React.CSSProperties;
  if (side === 'top') {
    style = {
      top: boundingBox.top - PADDING,
      transform: 'translateY(-100%)',
    };
  } else if (side === 'bottom') {
    style = {
      top: boundingBox.top + boundingBox.height + PADDING,
      transform: '',
    };
  } else if (side === 'left') {
    style = {
      left: boundingBox.left - PADDING,
      transform: 'translateX(-100%)',
    };
  } else {
    style = {
      left: boundingBox.left + boundingBox.width + PADDING,
      transform: '',
    };
  }
  style.width = maxWidth;

  if (side === 'right') {
    style.justifyContent = 'start';
  } else if (side === 'left') {
    style.justifyContent = 'end';
  } else if (side === 'top' || side === 'bottom') {
    if (alignment === 'center') {
      style.justifyContent = 'center';
    } else if (alignment === 'end') {
      style.justifyContent = 'end';
    }
  }

  if (side === 'top' || side === 'bottom') {
    if (alignment === 'start') {
      style.left = boundingBox.left;
    } else if (alignment === 'center') {
      style.left = boundingBox.left + boundingBox.width / 2;
      style.transform += ' translateX(-50%)';
    } else {
      style.right = window.innerWidth - boundingBox.right;
    }
  } else if (side === 'left' || side === 'right') {
    if (alignment === 'start') {
      style.top = boundingBox.top;
    } else if (alignment === 'center') {
      style.top = boundingBox.top + boundingBox.height / 2;
      style.transform += 'translateY(-50%)';
    } else {
      style.bottom = window.innerHeight - boundingBox.bottom;
    }
  }

  return style;
}

export interface InternalFixedTooltipPlacementProps {
  side: TooltipSide;
  alignment: TooltipAlignment;
  tooltip: JSX.Element;
  /**
   * Whether the content wrapper should fill its parent. This can be used in the case where e.g.
   * you are wrapping a button that you want to fill its parent.
   */
  shouldContentWrapperFillContainer?: boolean;
  /**
   * When undefined, the tooltip will show/hide on mouse enter/leave, respectively.
   * When false, the tooltip will not be shown, regardless of mouse positioning.
   * When true, the tooltip will always be shown, again regardless of mouse positioning.
   */
  overrideShow?: boolean;
  /** When defined, the tooltip will stay open if the mouse is over the tooltip. */
  interactive?: boolean;
  mouseLeaveDelayMs?: number;
  maxWidth: number;
  children?: React.ReactNode;
}

const InternalFixedTooltipPlacement: React.FC<InternalFixedTooltipPlacementProps> = (props) => {
  const {
    children,
    interactive,
    side,
    alignment,
    tooltip,
    shouldContentWrapperFillContainer,
    overrideShow,
    mouseLeaveDelayMs,
    maxWidth,
  } = props;

  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const [mouseInteracting, setMouseInteracting] = useState<boolean>(false);
  const [mouseLeaveTimeoutId, setMouseLeaveTimeoutId] = useState<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);

  const enableShowOnMouseOver = overrideShow === undefined;
  const showTooltip =
    overrideShow || (enableShowOnMouseOver && mouseOver) || (interactive && mouseInteracting);

  const ref = useRef<HTMLDivElement>(null);
  const style =
    showTooltip && ref.current !== null
      ? computePositioning(side, alignment, ref.current.getBoundingClientRect(), maxWidth)
      : undefined;

  return (
    <div
      className={classNames('dragonfruit-tooltip-wrapper', {
        'should-fill-container': shouldContentWrapperFillContainer,
      })}
      onMouseEnter={enableShowOnMouseOver ? (): void => setMouseOver(true) : undefined}
      onMouseLeave={
        enableShowOnMouseOver
          ? (): void => {
              if (interactive) {
                // For interactive tooltips, delay closing the tooltip so that the user has a brief window to move their mouse to the tooltip.
                const timeoutId = setTimeout(() => {
                  setMouseOver(false);
                }, mouseLeaveDelayMs ?? MOUSE_LEAVE_DELAY_MS);
                setMouseLeaveTimeoutId(timeoutId);
              } else {
                setMouseOver(false);
              }
            }
          : undefined
      }
      ref={ref}
    >
      {children}
      {showTooltip && (
        <Portal node={document.getElementById(DRAGONFRUIT_PORTAL_ROOT_ID)}>
          <div
            className="dragonfruit-tooltip-placement fixed"
            style={style}
            // It's possible to move your mouse in such a way that you hover the tooltip and it doesn't close,
            // so we have to do that manually.
            onMouseEnter={
              interactive
                ? (): void => {
                    setMouseOver(false);
                    setMouseInteracting(true);
                    if (mouseLeaveTimeoutId !== undefined) {
                      clearTimeout(mouseLeaveTimeoutId);
                      setMouseLeaveTimeoutId(undefined);
                    }
                  }
                : (): void => setMouseOver(false)
            }
            onMouseLeave={
              interactive
                ? (): void => {
                    setMouseInteracting(false);
                  }
                : undefined
            }
          >
            <div className="tooltip-wrapper">{tooltip}</div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default InternalFixedTooltipPlacement;

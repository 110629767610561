import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Portal } from 'react-portal';
import { ctrlOrCmdKeyPressed, KEY } from '@applied/utils/keyboard';
import classNames from 'classnames';

import './modal.scss';

import { DRAGONFRUIT_PORTAL_ROOT_ID } from '../../portal';
import ActionButton from '../ActionButton';
import ButtonWithFeedback from '../ButtonWithFeedback';
import type { MoreInfoIconProps } from '../MoreInfoIcon/MoreInfoIcon';
import MoreInfoIcon from '../MoreInfoIcon/MoreInfoIcon';

export const DRAGONFRUIT_MODAL_OVERLAY_CLASSNAME = 'dragonfruit-modal-overlay';

export enum ModalResponse {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
}

export interface ModalButtonProps {
  /**
   * Called when the button is clicked - optionally returning a boolean indicating whether
   * the modal should be prevented from closing.
   */
  onClick?: () => boolean | void | Promise<void> | Promise<boolean>;
  disabled?: boolean;
  loading?: boolean;
  text: string;
}

export interface PrimaryModalButtonProps extends ModalButtonProps {
  icon?: string;
  /**
   * For 1 second after the button is clicked, the button will be disabled and
   * display this text as a tooltip.
   */
  feedbackText?: string;
}

export interface ModalProps {
  title?: string;
  /**
   * If moreInfoIconProps is set, then displays an info icon next to the title that shows the given
   * text in a tooltip upon hover.
   */
  moreInfoIconProps?: MoreInfoIconProps;
  className?: string;
  icon?: JSX.Element;
  topRightActions?: JSX.Element[];
  description?: string;
  errorMessage?: string;
  onClose: (response?: ModalResponse) => void;
  primaryButton?: PrimaryModalButtonProps;
  secondaryButton?: ModalButtonProps;
  tertiaryButton?: ModalButtonProps;
  bottomLeftElement?: JSX.Element;
  /**
   * If leftSidebarElement is provided, the modal styling updates to allow for a vertical sidebar to the left of the modal inner content.
   * Consumed internally by MultiStepModal which offers a vertical, clickable progress sidebar.
   */
  leftSidebarElement?: JSX.Element;
  ariaLabel?: string;
  onContentScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
  testId?: string;
  /**
   * If provided, disable the 1) mouse-down-outside-of-modal and 2) escape-button-press close gestures.
   * This can be particularly helpful for content-rich modals where the user has to fill out
   * multiple fields, and accidentally closing out the modal would be a frustrating experience.
   */
  disableCloseGestures?: boolean;
  /**
   * Optionally wrap the size of the Modal children rather than using a fixed width.
   *
   * This will horizontally expand and vertically scroll, up to the 85% of the screen in
   * either direction.
   */
  fitContent?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

// Trigger screenshot tests.
const InternalModal: React.FC<ModalProps> = (props) => {
  const {
    title,
    moreInfoIconProps,
    className,
    icon,
    description,
    errorMessage,
    onClose,
    primaryButton,
    secondaryButton,
    tertiaryButton,
    bottomLeftElement,
    leftSidebarElement,
    children,
    topRightActions,
    ariaLabel,
    onContentScroll,
    testId = 'modal-overlay',
    disableCloseGestures: disableGestureClose,
    fitContent,
  } = props;
  const modalRef = useRef<HTMLDivElement>(null);

  // Focus the modal on mount to prevent "leaking" keystrokes
  // to the previously focused element.
  useEffect(() => {
    modalRef.current?.focus();
  }, []);

  // Whether the last mousedown event occurred outside the modal.
  const [mouseDownOutside, setMouseDownOutside] = useState(false);

  const maybeClose = useCallback(() => {
    if (mouseDownOutside && !disableGestureClose) {
      onClose?.();
    }
  }, [mouseDownOutside, onClose, disableGestureClose]);

  // This is a slight hack because accessing primaryButton?.onClick in clickPrimaryButton means the hook
  // has to depend on primaryButton, which may change (shallowly) every render.
  const primaryButtonOnClick = primaryButton?.onClick;
  const clickPrimaryButton = useCallback(async (): Promise<void> => {
    // TODO(erichiggins): refactor existing modals so this doesn't have to block.
    const preventClosing = (await primaryButtonOnClick?.()) || primaryButton?.feedbackText;
    if (!preventClosing) {
      onClose?.(ModalResponse.primary);
    }
  }, [onClose, primaryButtonOnClick, primaryButton?.feedbackText]);

  const clickSecondaryButton = async (): Promise<void> => {
    // TODO(erichiggins): refactor existing modals so this doesn't have to block.
    const preventClosing = await secondaryButton?.onClick?.();
    if (!preventClosing) {
      onClose?.(ModalResponse.secondary);
    }
  };

  const clickTertiaryButton = async (): Promise<void> => {
    // TODO(erichiggins): refactor existing modals so this doesn't have to block.
    const preventClosing = await tertiaryButton?.onClick?.();
    if (!preventClosing) {
      onClose?.(ModalResponse.tertiary);
    }
  };

  useEffect(() => {
    const onKeyDown = (ev: KeyboardEvent): void => {
      if (ev.key === KEY.ENTER && ctrlOrCmdKeyPressed(ev) && !primaryButton?.disabled) {
        clickPrimaryButton();
      }
      if (ev.key === KEY.ESCAPE && !disableGestureClose) {
        onClose?.();
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => document.removeEventListener('keydown', onKeyDown);
  }, [clickPrimaryButton, onClose, primaryButton?.disabled, disableGestureClose]);

  return (
    <div
      className={DRAGONFRUIT_MODAL_OVERLAY_CLASSNAME}
      onMouseDown={(ev): void => {
        ev.stopPropagation();
        setMouseDownOutside(true);
      }}
      onMouseUp={(ev): void => {
        ev.stopPropagation();
        maybeClose();
      }}
      onClick={(ev): void => ev.stopPropagation()}
      data-testid={testId}
    >
      <div
        ref={modalRef}
        // divs are not focusable by default, so we need to add a tabIndex.
        tabIndex={0}
        className={classNames('dragonfruit-modal', className)}
        onMouseDown={(ev): void => {
          setMouseDownOutside(false);
          ev.stopPropagation();
        }}
        onMouseUp={(ev): void => ev.stopPropagation()}
        role="dialog"
        aria-label={ariaLabel}
        style={{
          width: fitContent ? 'fit-content' : undefined,
        }}
      >
        {leftSidebarElement}
        <div className="modal-primary-content">
          <div className="modal-header">
            {title && (
              <div className="modal-title-row">
                {icon && <div className="modal-title-left-icon">{icon}</div>}
                <div className="text-style-title modal-title">
                  {title}
                  {moreInfoIconProps && (
                    <MoreInfoIcon
                      tooltip={moreInfoIconProps.tooltip}
                      link={moreInfoIconProps.link}
                    />
                  )}
                </div>
                {topRightActions && <div className="top-right-actions">{topRightActions}</div>}
              </div>
            )}
            {description && <div className="text-style-caption">{description}</div>}
          </div>
          <div
            className={classNames('modal-content-container', {
              'after-modal-content-margin':
                !!children &&
                (!!primaryButton || !!secondaryButton || !!tertiaryButton || !!bottomLeftElement),
            })}
          >
            <div className="modal-content" onScroll={onContentScroll}>
              {children}
            </div>
            {errorMessage && (
              <div className="error-message text-style-modal-error text-style-caption">
                {errorMessage}
              </div>
            )}
          </div>
          <div className={classNames({ 'bottom-row': bottomLeftElement })}>
            <div className="bottom-left-element">{bottomLeftElement}</div>
            <div className="modal-buttons">
              {tertiaryButton && (
                <ActionButton
                  onClick={clickTertiaryButton}
                  appearance="invisible"
                  size="medium"
                  disabled={tertiaryButton.disabled}
                >
                  {tertiaryButton.text}
                </ActionButton>
              )}
              {secondaryButton && (
                <ActionButton
                  onClick={clickSecondaryButton}
                  appearance="invisible"
                  size="medium"
                  disabled={secondaryButton.disabled}
                >
                  {secondaryButton.text}
                </ActionButton>
              )}
              {primaryButton &&
                (primaryButton?.feedbackText ? (
                  <ButtonWithFeedback
                    onClick={clickPrimaryButton}
                    appearance="primary"
                    size="medium"
                    feedbackText={primaryButton.feedbackText}
                    disabled={primaryButton.disabled}
                    iconBeforeSrc={primaryButton.icon}
                    loading={primaryButton.loading}
                  >
                    {primaryButton.text}
                  </ButtonWithFeedback>
                ) : (
                  <ActionButton
                    onClick={clickPrimaryButton}
                    appearance="primary"
                    size="medium"
                    disabled={primaryButton.disabled}
                    iconBeforeSrc={primaryButton.icon}
                    loading={primaryButton.loading}
                  >
                    {primaryButton.text}
                  </ActionButton>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Imperative interface for modals. This should be used for simple modals that for the most part
 * only require confirmation (i.e. the only way the user can interact with the modal is via the
 * default primary/secondary/tertiary buttons).
 * @param props The props with which to render the modal component.
 * @returns A promise that resolves when the modal is closed.
 */
export const showModal = (props: Omit<ModalProps, 'onClose'>): Promise<ModalResponse> => {
  return new Promise<ModalResponse>((resolve) => {
    const element = document.createElement('div');
    let portalRoot = document.getElementById(DRAGONFRUIT_PORTAL_ROOT_ID);
    // Create the portal root if it doesn't exist. I'm pretty sure this can only happen in unit tests.
    if (portalRoot === null) {
      portalRoot = document.createElement('div');
      portalRoot.id = DRAGONFRUIT_PORTAL_ROOT_ID;
      portalRoot.style.zIndex = '10';
      portalRoot.style.left = '0';
      portalRoot.style.top = '0';
      portalRoot.style.width = '100%';
      document.getElementsByTagName('body').item(0)?.appendChild(portalRoot);
    }

    portalRoot.appendChild(element);
    ReactDOM.render(
      <InternalModal
        {...props}
        onClose={(response: ModalResponse): void => {
          ReactDOM.unmountComponentAtNode(element);
          portalRoot?.removeChild(element);
          resolve(response);
        }}
      />,
      element,
    );
  });
};

/**
 * Creates a foregrounded InternalModal component by wrapping it a Portal, rendering it outside the parent's DOM hierarchy.
 * Should only be used by other dragonfruit components building on InternalModal directly. For example usage, check out MultiStepModal.
 * @param props InternalModalProps to pass to the inner InternalModal.
 * @returns InternalModal component wrapped in a React Portal.
 */
export const wrapInternalModalInPortal = (props: ModalProps): JSX.Element => {
  return (
    <Portal node={document.getElementById(DRAGONFRUIT_PORTAL_ROOT_ID)}>
      <InternalModal {...props} />
    </Portal>
  );
};

/**
 * Declarative interface for modals. This should be used for complicated modals with e.g. text fields,
 * dropdowns, etc. in the modal contents.
 */
const Modal: React.FC<Omit<ModalProps, 'leftSidebarElement'>> = (props) => {
  return wrapInternalModalInPortal(props);
};

export default Modal;

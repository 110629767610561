import type { CSSProperties, DragEvent, KeyboardEventHandler } from 'react';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { ctrlOrCmdKeyPressed, KEY } from '@applied/utils/keyboard';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import './text-field.scss';

import { Appearance, HIGHLIGHT_COLOR } from '../../colors/colors';
import type { MoreInfoIconProps } from '../MoreInfoIcon/MoreInfoIcon';
import MoreInfoIcon from '../MoreInfoIcon/MoreInfoIcon';

// https://ux.stackexchange.com/questions/38543/amount-of-time-to-determine-a-user-has-stopped-typing
export const DEFER_INPUT_UPDATE_TIMEOUT = 1000;

export enum TextType {
  REGULAR = 'regular',
  CODE = 'code',
}

export enum HTMLResize {
  NONE = 'none',
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
  BOTH = 'both',
}

/**
 * Sentinel value to return in validator functions to indicate validation passed.
 */
export const VALIDATION_SUCCESS_SENTINEL: string | undefined = undefined;
/**
 * Sentinel value to return in validator functions if you want to indicate
 * validation failed but do not want to return an error message. Must also pass in
 * the `hideErrorMessage` prop to fully hide the error message in all cases.
 */
export const VALIDATION_FAIL_SENTINEL = 'Invalid input.';

interface BaseTextFieldProps {
  value: string;
  onChange?: (value: string) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  // When there's no error message, pass null or undefined if this input cannot error,
  // or an empty string if it can. This will reserve vertical space for an error message,
  // so the component doesn't move if an error appears.
  errorMessage?: string;
  warningMessage?: string;
  caption?: string;
  /**
   * If moreInfoIconProps is set, then displays an info icon next to the title that shows the given
   * text in a tooltip upon hover.
   */
  moreInfoIconProps?: MoreInfoIconProps;
  // Shift the input down by either 8px or 16px to offset the height of the error message.
  // This can be used if you want the input to be centered vertically and it is not.
  shiftDown?: 'none' | 'half' | 'full';
  // This is only here so this component is reusable by SearchField.
  icon?: JSX.Element;
  afterIcon?: JSX.Element;
  /*
   * The prefix comes before the input section of the textfield. It can be either an element or
   * a string. If the prefix is an element, then the styling will need to be handled by the parent
   * for hover and disabled states.
   */
  prefix?: JSX.Element | string;
  /*
   * The suffix appears after the input section of the textfield. It can be either an element or
   * a string. If the prefix is an element, then the styling will need to be handled by the parent
   * for hover and disabled states.
   */
  suffix?: JSX.Element | string;
  shouldFillContainer?: boolean;
  className?: string;
  smallPadding?: number;
  mediumPadding?: number;
  largePadding?: number;
  // Regular is the default text. Code will use $font-family:"JetBrains Mono", monospace
  textType?: TextType;
  onClick?: () => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: () => void;
  /**
   * Callback to enforce constraints on the text field input.
   * The return value is the error message if validation fails,
   * or undefined (optionally VALIDATION_SUCCESS_SENTINEL) if validation passes.
   */
  validator?: (value: string) => string | undefined;
  /**
   * If true, hides the text field error message even if validation fails.
   * Validation can fail for invalid number inputs and on the custom validator function if provided.
   *
   * This prop should almost never be used; one valid use case is if the error message is too long
   * for the text field.
   *
   * If using the `validator` too, the validator function can return any placeholder error message
   * (optionally VALIDATION_FAIL_SENTINEL) to indicate a failure.
   */
  hideErrorMessage?: boolean;
  onSubmit?: () => void;

  /**
   * This is included only for backwards compatibility reasons to keep new search bars consistent
   * with the old UI. Use onSubmit wherever possible.
   */
  onFinishedTyping?: (value: string) => void;
  /**
   * Whether the text field should be automatically focused when it appears on screen.
   */
  focusOnMount?: boolean;
  /**
   * Whether to highlight all text in the input when it is focused.
   */
  selectOnFocus?: boolean;
  resize?: HTMLResize;
  rows?: number;
  onKeyDown?: KeyboardEventHandler;
  /** Callback to process files (i.e. upload) dropped into the TextArea. */
  onDropFiles?: (f: FileList) => void;
  /** Prevents the default behavior of blurring the input on submit */
  preventBlurOnSubmit?: boolean;
  /**
   * This should almost always be set to 'center', but in the multi select input
   * we need it to be 'flex-start' because the input can be multiple lines, so the
   * search and arrow icons need to be aligned to the top.
   */
  verticalAlign?: CSSProperties['alignItems'];
  inputRef?: React.RefObject<HTMLInputElement & HTMLTextAreaElement>;
  onKeydownCallback?: (
    event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>,
    value: string,
  ) => void;
  inputType?: string;
  appearance?: Appearance;
  shouldRevalidateOnValueChange?: boolean;
  /**
   * Whether we should only show the error message after the first blur event.
   **/
  showErrorMessageOnFirstBlur?: boolean;
}

export interface InputTextFieldProps extends Omit<BaseTextFieldProps, 'rows'> {
  type: 'input';
  height: 'small' | 'medium' | 'large';
  inputType: 'text';
}

export interface InternalPasswordFieldProps extends BaseTextFieldProps {
  type: 'input';
  height: 'small' | 'medium' | 'large';
  inputType: 'password';
}

export interface TextAreaTextFieldProps extends BaseTextFieldProps {
  type: 'textarea';
}

type InternalTextFieldProps =
  | InputTextFieldProps
  | InternalPasswordFieldProps
  | TextAreaTextFieldProps;

// TextField that's reusable between search and regular text fields. This should not be used directly
// except in SearchField - instead we should use the default exported TextField.
export const InternalTextField = forwardRef<HTMLElement, InternalTextFieldProps>(
  function InternalTextField(props, ref: React.RefObject<HTMLDivElement>) {
    const {
      inputRef: propInputRef,
      value,
      onChange,
      onPaste,
      disabled,
      placeholder,
      errorMessage,
      warningMessage,
      caption,
      moreInfoIconProps,
      icon,
      prefix,
      suffix,
      type,
      shouldFillContainer,
      className,
      smallPadding,
      mediumPadding,
      largePadding,
      textType,
      onClick,
      afterIcon,
      validator,
      hideErrorMessage,
      onBlur,
      focusOnMount,
      selectOnFocus,
      onSubmit,
      resize,
      onFocus,
      onFinishedTyping,
      readOnly,
      preventBlurOnSubmit,
      onKeyDown,
      onDropFiles,
      verticalAlign,
      onKeydownCallback,
      inputType,
      appearance,
      shouldRevalidateOnValueChange,
      showErrorMessageOnFirstBlur,
    } = props;

    // The validation error message can be set if a validator fails. However, the errorMessage
    // prop will take precedence over the validation error message if both exist.
    const [validationErrorMessage, setValidationErrorMessage] = useState('');
    const [hasLostFocusAtLeastOnce, setHasLostFocusAtLeastOnce] = useState(false);
    const previousValueRef = useRef(value);

    const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
    // If ref is passed through props, use that one instead;
    const selectedInputRef = propInputRef ? propInputRef : inputRef;

    const rows = 'rows' in props ? props.rows : undefined;

    const debouncedOnChange = useRef<(value: string) => void | undefined>();

    /*
     * Updates the validation error message state based on the given value.
     * Returns where the given value failed validation.
     */
    const updateValidationErrorMessage = useCallback(
      (newValue: string): boolean => {
        const currentValidationErrorMessage = validator?.(newValue ?? '');
        if (currentValidationErrorMessage) {
          if (hideErrorMessage) {
            setValidationErrorMessage('');
          } else {
            setValidationErrorMessage(currentValidationErrorMessage);
          }
          return true;
        }
        // If error message was not set explicitly, then we do not want to display a message.
        setValidationErrorMessage('');
        return false;
      },
      [hideErrorMessage, setValidationErrorMessage, validator],
    );

    useEffect(() => {
      if (onFinishedTyping) {
        debouncedOnChange.current = debounce(onFinishedTyping, DEFER_INPUT_UPDATE_TIMEOUT);
      }
    }, [onFinishedTyping]);

    useEffect(() => {
      if (focusOnMount) {
        selectedInputRef.current?.focus();
      }
    }, [focusOnMount, selectedInputRef]);

    useEffect(() => {
      if (shouldRevalidateOnValueChange) {
        // If the value is different, then we changed the value not as a result of the user typing.
        if (value !== previousValueRef.current) {
          previousValueRef.current = value;
          updateValidationErrorMessage(value);
        }
      }
    }, [value, shouldRevalidateOnValueChange, updateValidationErrorMessage]);

    /**
     * Pulls out file(s) from the `onDrop` event, and then iteratively
     * runs the `onDropFile` handler prop on each file.
     *
     * See: https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop#process_the_drop
     */
    const handleDroppedFiles = (
      ev: DragEvent<HTMLInputElement> & DragEvent<HTMLTextAreaElement>,
    ): void => {
      if (!onDropFiles) {
        // Do nothing if we have no File handler.
        return;
      }
      if (!ev.dataTransfer.files) {
        // Do nothing if there are no files to handle>
        return;
      }
      // Use DataTransfer interface to access the file(s)
      onDropFiles(ev.dataTransfer.files);
      ev.preventDefault();
      ev.stopPropagation();
    };
    function getMarginBottom(): string | undefined {
      const { shiftDown } = props;

      if (shiftDown === 'half') {
        return '-8px';
      } else if (shiftDown === 'full') {
        return '-16px';
      } else {
        return undefined;
      }
    }

    let padding: number | undefined = 0;
    if (type === 'input') {
      if (props.height === 'medium') {
        padding = mediumPadding;
      } else if (props.height === 'small') {
        padding = smallPadding;
      } else {
        padding = largePadding;
      }
    }

    const Type = type;

    const shouldHideErrorMessageDueToNoBlur =
      showErrorMessageOnFirstBlur && !hasLostFocusAtLeastOnce;
    const showErrorMessage =
      !shouldHideErrorMessageDueToNoBlur &&
      (Boolean(validationErrorMessage) || Boolean(errorMessage)) &&
      !disabled;
    const showWarningMessage = Boolean(warningMessage) && !showErrorMessage && !disabled;
    /**
     * Determines whether the error or warning message should be shown and
     * returns the respective component to display it.
     */
    function errorWarningMessageComponent(): JSX.Element | null | undefined {
      if (disabled || hideErrorMessage || shouldHideErrorMessageDueToNoBlur) {
        return null;
      } else if (validationErrorMessage || errorMessage) {
        return (
          <p className="error-message text-style-caption" data-e2e="textfield-error-message">
            {errorMessage || validationErrorMessage}
          </p>
        );
      } else if (warningMessage) {
        return (
          <p className="warning-message text-style-caption" data-e2e="textfield-warning-message">
            {warningMessage}
          </p>
        );
      }
    }

    const handleBlur = useCallback(
      (_event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        onBlur?.();
        setHasLostFocusAtLeastOnce(true);
      },
      [onBlur],
    );

    const iconBeforeComponent = icon && <div style={{ marginRight: 4 }}>{icon}</div>;
    const iconAfterComponent = afterIcon && <div style={{ marginRight: 4 }}>{afterIcon}</div>;
    return (
      <div
        style={{ marginBottom: getMarginBottom() }}
        className={classNames('dragonfruit-input-wrapper', className, {
          disabled,
          'dragonfruit-input-wrapper-fill-container': shouldFillContainer,
          readonly: readOnly,
        })}
      >
        {caption && (
          <label
            className="label text-style-caption"
            style={appearance === Appearance.HIGHLIGHT ? { color: HIGHLIGHT_COLOR } : {}}
          >
            {caption}
            {moreInfoIconProps && <MoreInfoIcon {...moreInfoIconProps} />}
          </label>
        )}
        <div
          className={classNames('text-field', `text-field-${type}`, {
            error: showErrorMessage,
            warning: showWarningMessage,
            readonly: readOnly,
          })}
          style={{
            paddingTop: padding,
            paddingBottom: padding,
            alignItems: verticalAlign || 'center',
          }}
          onClick={(): void => {
            if (!disabled) {
              selectedInputRef.current?.focus();
              onClick?.();
            }
          }}
          ref={ref}
          data-testid="dragonfruit-text-field"
        >
          {iconBeforeComponent}
          <div className="before-input-and-input" data-testid="text-field-inner">
            {prefix &&
              (typeof prefix === 'string' ? (
                <div className={classNames('prefix-text text-style-body', { disabled })}>
                  {prefix}
                </div>
              ) : (
                prefix
              ))}
            <Type
              type={inputType}
              ref={selectedInputRef}
              onPaste={onPaste}
              className={classNames('text-style-body', `text-style-body-${textType}`, { disabled })}
              onKeyDown={(ev): void => {
                if (onKeyDown) {
                  ev.stopPropagation();
                  onKeyDown(ev);
                  return;
                }
                if (ev.key === KEY.ESCAPE) {
                  ev.stopPropagation();
                  selectedInputRef.current?.blur();
                } else if (ev.key === KEY.ENTER && ctrlOrCmdKeyPressed(ev) && type === 'textarea') {
                  // We want submit to happen on cmd+enter or ctrl+enter.
                  ev.preventDefault();

                  // Only stop propagation if an onSubmit is provided.
                  if (onSubmit) {
                    ev.stopPropagation();
                    onSubmit();
                  }
                } else if (ev.key === KEY.ENTER && type === 'input') {
                  if (!preventBlurOnSubmit) {
                    selectedInputRef.current?.blur();
                  }
                  // Only stop propagation if an onSubmit is provided.
                  if (onSubmit) {
                    ev.stopPropagation();
                    onSubmit();
                  }
                } else if (ev.key === KEY.ENTER) {
                  ev.stopPropagation();
                }
                if (onKeydownCallback) {
                  onKeydownCallback(ev, value);
                }
              }}
              onDrop={handleDroppedFiles}
              rows={rows}
              style={{ resize: resize ?? HTMLResize.NONE }}
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
                const hasErrorMessage = updateValidationErrorMessage(e.target.value);
                if (!hasErrorMessage) {
                  previousValueRef.current = e.target.value;
                  onChange?.(e.target.value ?? '');
                  debouncedOnChange.current?.(e.target.value);
                }
              }}
              disabled={disabled}
              placeholder={placeholder}
              onBlur={handleBlur}
              onFocus={(ev): void => {
                onFocus?.(ev);
                if (selectOnFocus) {
                  selectedInputRef.current?.select();
                }
              }}
              readOnly={readOnly}
              aria-label={caption}
            />
            {suffix &&
              (typeof suffix === 'string' ? (
                <div className={classNames('suffix-text text-style-body', { disabled })}>
                  {suffix}
                </div>
              ) : (
                suffix
              ))}
          </div>
          {iconAfterComponent}
        </div>
        {errorWarningMessageComponent()}
      </div>
    );
  },
);

type OmittedInternalTextFieldProps =
  | 'type'
  | 'inputType'
  | 'smallPadding'
  | 'mediumPadding'
  | 'largePadding'
  | 'verticalAlign';
export type TextFieldProps = Omit<InputTextFieldProps, OmittedInternalTextFieldProps>;

const TextField = forwardRef<HTMLElement, TextFieldProps>(function TextField(props, ref) {
  return (
    <InternalTextField
      ref={ref}
      type="input"
      inputType="text"
      smallPadding={0.5}
      mediumPadding={7}
      largePadding={11}
      {...props}
    />
  );
});

export default TextField;

import React from 'react';

import './more-info-icon.scss';

import { DragonFruitIcons } from '../../icons/icons';
import Icon from '../Icon/Icon';
import ConditionalLink from '../Link/ConditionalLink';
import type { TooltipAlignment, TooltipSide } from '../Tooltip/InternalFixedTooltipPlacement';
import Tooltip from '../Tooltip/Tooltip';

export interface MoreInfoIconProps {
  /**
   * Tooltip that appears upon hovering on the info icon. Even if there is an onClick,
   * there should be a tooltip describing what will happen if the user clicks on it.
   */
  tooltip: string | JSX.Element;
  /**
   * Link to redirect to.
   *
   * Relative links (determined by whether the input starts with "/") will open in the current tab.
   * Absolute links will open in a new tab by default.
   */
  link?: string;

  tooltipAlignment?: TooltipAlignment;

  /** Indicates whether the tooltip is an interactive tooltip or not. */
  interactive?: boolean;

  tooltipSide?: TooltipSide;
}

const MoreInfoIcon: React.FC<MoreInfoIconProps> = (props) => {
  const { interactive, tooltip, link, tooltipAlignment = 'start', tooltipSide = 'bottom' } = props;

  return (
    <Tooltip
      tooltip={tooltip}
      placementType="fixed"
      alignment={tooltipAlignment}
      side={tooltipSide}
      interactive={interactive}
    >
      <ConditionalLink link={link}>
        <div
          className="more-info-icon"
          aria-label="More info"
          onClick={(ev): void => {
            ev.stopPropagation();
          }}
        >
          <Icon src={DragonFruitIcons.INFO} height={14} />
        </div>
      </ConditionalLink>
    </Tooltip>
  );
};

export default MoreInfoIcon;

import TwoD from '../assets/icons/2D.svg';
import ThreeD from '../assets/icons/3D.svg';
import Account from '../assets/icons/Account.svg';
import AccountTree from '../assets/icons/AccountTree.svg';
import Activity from '../assets/icons/Activity.svg';
import ActorChannels from '../assets/icons/ActorChannels.svg';
import Actors from '../assets/icons/Actors.svg';
import Add from '../assets/icons/Add.svg';
import AddLine from '../assets/icons/AddLine.svg';
import AddParkingBlock from '../assets/icons/AddParkingBlock.svg';
import AddPerson from '../assets/icons/AddPerson.svg';
import AddRegion from '../assets/icons/AddRegion.svg';
import AddRoad from '../assets/icons/AddRoad.svg';
import AddSign from '../assets/icons/AddSign.svg';
import Analytics from '../assets/icons/Analytics.svg';
import Archive from '../assets/icons/Archive.svg';
import ArrowBack from '../assets/icons/ArrowBack.svg';
import ArrowClockwise from '../assets/icons/ArrowClockwise.svg';
import ArrowCounterClockwise from '../assets/icons/ArrowCounterClockwise.svg';
import ArrowDown from '../assets/icons/ArrowDown.svg';
import ArrowDropdown from '../assets/icons/ArrowDropdown.svg';
import ArrowForward from '../assets/icons/ArrowForward.svg';
import ArrowRight from '../assets/icons/ArrowRight.svg';
import ArrowUp from '../assets/icons/ArrowUp.svg';
import Assessment from '../assets/icons/Assessment.svg';
import Assignment from '../assets/icons/Assignment.svg';
import AssignmentLate from '../assets/icons/AssignmentLate.svg';
import Attach from '../assets/icons/Attach.svg';
import AutoArrange from '../assets/icons/AutoArrange.svg';
import AutoGraph from '../assets/icons/AutoGraph.svg';
import Ballot from '../assets/icons/Ballot.svg';
import BarChart from '../assets/icons/BarChart.svg';
import BatchComparison from '../assets/icons/BatchComparison.svg';
import BatchRun from '../assets/icons/BatchRun.svg';
import BlinkerStalkIdle from '../assets/icons/BlinkerStalkIdle.svg';
import Bookmark from '../assets/icons/Bookmark.svg';
import Bug from '../assets/icons/Bug.svg';
import Camera from '../assets/icons/Camera.svg';
import CameraRoll from '../assets/icons/CameraRoll.svg';
import CameraView from '../assets/icons/CameraView.svg';
import Cancel from '../assets/icons/Cancel.svg';
import Casino from '../assets/icons/Casino.svg';
import Chart from '../assets/icons/Chart.svg';
import Chat from '../assets/icons/Chat.svg';
import Check from '../assets/icons/Check.svg';
import CheckCircle from '../assets/icons/CheckCircle.svg';
import CheckCircleFilled from '../assets/icons/CheckCircleFilled.svg';
import ChevronDown from '../assets/icons/ChevronDown.svg';
import ChevronLeft from '../assets/icons/ChevronLeft.svg';
import ChevronRight from '../assets/icons/ChevronRight.svg';
import ChevronUp from '../assets/icons/ChevronUp.svg';
import Close from '../assets/icons/Close.svg';
import CloseFull from '../assets/icons/CloseFull.svg';
import Cloud from '../assets/icons/Cloud.svg';
import CloudDownload from '../assets/icons/CloudDownload.svg';
import CloudUpload from '../assets/icons/CloudUpload.svg';
import Code from '../assets/icons/Code.svg';
import CollectionsBookmark from '../assets/icons/CollectionsBookmark.svg';
import CollectionsImage from '../assets/icons/CollectionsImage.svg';
import Comment from '../assets/icons/Comment.svg';
import Compare from '../assets/icons/Compare.svg';
import Config from '../assets/icons/Config.svg';
import Connect from '../assets/icons/Connect.svg';
import ContainedSpline from '../assets/icons/ContainedSpline.svg';
import Copilot from '../assets/icons/Copilot.svg';
import Copy from '../assets/icons/Copy.svg';
import Create from '../assets/icons/Create.svg';
import Cyclist from '../assets/icons/Cyclist.svg';
import Dashboard from '../assets/icons/Dashboard.svg';
import DashboardConfiguration from '../assets/icons/DashboardConfiguration.svg';
import DashboardCustomize from '../assets/icons/DashboardCustomize.svg';
import DataObjects from '../assets/icons/DataObjects.svg';
import Date from '../assets/icons/Date.svg';
import Default from '../assets/icons/Default.svg';
import Delete from '../assets/icons/Delete.svg';
import Description from '../assets/icons/Description.svg';
import DirectSelect from '../assets/icons/DirectSelect.svg';
import DoubleSidedParallelogram from '../assets/icons/DoubleSidedParallelogram.svg';
import DoubleSidedTrapezoid from '../assets/icons/DoubleSidedTrapezoid.svg';
import Download from '../assets/icons/Download.svg';
import Drag from '../assets/icons/Drag.svg';
import Drawings from '../assets/icons/Drawings.svg';
import DriveFolderUpload from '../assets/icons/DriveFolderUpload.svg';
import Ego from '../assets/icons/Ego.svg';
import Environments from '../assets/icons/Environments.svg';
import Error from '../assets/icons/Error.svg';
import ErrorFilled from '../assets/icons/ErrorFilled.svg';
import ExitFullscreen from '../assets/icons/ExitFullscreen.svg';
import Expand from '../assets/icons/Expand.svg';
import Explore from '../assets/icons/Explore.svg';
import FactCheck from '../assets/icons/FactCheck.svg';
import Favorite from '../assets/icons/Favorite.svg';
import Feedback from '../assets/icons/Feedback.svg';
import FileUpload from '../assets/icons/FileUpload.svg';
import FilterAlt from '../assets/icons/FilterAlt.svg';
import FilterList from '../assets/icons/FilterList.svg';
import Fix from '../assets/icons/Fix.svg';
import Flag from '../assets/icons/Flag.svg';
import FlipToFront from '../assets/icons/FlipToFront.svg';
import FloatTile from '../assets/icons/FloatTile.svg';
import Folder from '../assets/icons/Folder.svg';
import FollowEgo from '../assets/icons/FollowEgo.svg';
import Fullscreen from '../assets/icons/Fullscreen.svg';
import Globe from '../assets/icons/Globe.svg';
import GridView from '../assets/icons/GridView.svg';
import GuiEditor from '../assets/icons/GuiEditor.svg';
import Help from '../assets/icons/Help.svg';
import HelpOutline from '../assets/icons/HelpOutline.svg';
import HighlightOff from '../assets/icons/HighlightOff.svg';
import Highlights from '../assets/icons/Highlights.svg';
import History from '../assets/icons/History.svg';
import Hive from '../assets/icons/Hive.svg';
import Home from '../assets/icons/Home.svg';
import HorizontalHierarchy from '../assets/icons/HorizontalHierarchy.svg';
import Image from '../assets/icons/Image.svg';
import Inbox from '../assets/icons/Inbox.svg';
import Includes from '../assets/icons/Includes.svg';
import Info from '../assets/icons/Info.svg';
import InfoOutline from '../assets/icons/InfoOutline.svg';
import Inventory from '../assets/icons/Inventory.svg';
import Label from '../assets/icons/Label.svg';
import Lan from '../assets/icons/Lan.svg';
import Layers from '../assets/icons/Layers.svg';
import Layout from '../assets/icons/Layout.svg';
import LeftBlinkerHold from '../assets/icons/LeftBlinkerHold.svg';
import Library from '../assets/icons/Library.svg';
import Like from '../assets/icons/Like.svg';
import Link from '../assets/icons/Link.svg';
import LinkOff from '../assets/icons/LinkOff.svg';
import List from '../assets/icons/List.svg';
import ListOL from '../assets/icons/ListOL.svg';
import ListUL from '../assets/icons/ListUL.svg';
import Loading from '../assets/icons/Loading.svg';
import Lock from '../assets/icons/Lock.svg';
import Login from '../assets/icons/Login.svg';
import Logout from '../assets/icons/Logout.svg';
import Logs from '../assets/icons/Logs.svg';
import Map from '../assets/icons/Map.svg';
import Materials from '../assets/icons/Materials.svg';
import MathIcon from '../assets/icons/Math.svg';
import Measure from '../assets/icons/Measure.svg';
import MergeType from '../assets/icons/MergeType.svg';
import MoreHoriz from '../assets/icons/MoreHoriz.svg';
import MoreVert from '../assets/icons/MoreVert.svg';
import NewFolder from '../assets/icons/NewFolder.svg';
import Notifications from '../assets/icons/Notifications.svg';
import Observers from '../assets/icons/Observers.svg';
import OfflineBolt from '../assets/icons/OfflineBolt.svg';
import OpenDoor from '../assets/icons/OpenDoor.svg';
import OpenFull from '../assets/icons/OpenFull.svg';
import OpenInNew from '../assets/icons/OpenInNew.svg';
import OpenTabs from '../assets/icons/OpenTabs.svg';
import Overlay from '../assets/icons/Overlay.svg';
import Palette from '../assets/icons/Palette.svg';
import Pan from '../assets/icons/Pan.svg';
import Parameters from '../assets/icons/Parameters.svg';
import Pedestrian from '../assets/icons/Pedestrian.svg';
import Pen from '../assets/icons/Pen.svg';
import Pending from '../assets/icons/Pending.svg';
import PendingOutline from '../assets/icons/PendingOutline.svg';
import People from '../assets/icons/People.svg';
import Person from '../assets/icons/Person.svg';
import Play from '../assets/icons/Play.svg';
import Playlist from '../assets/icons/Playlist.svg';
import PlaylistAdd from '../assets/icons/PlaylistAdd.svg';
import Polygon from '../assets/icons/Polygon.svg';
import Polyline from '../assets/icons/Polyline.svg';
import PowerButton from '../assets/icons/PowerButton.svg';
import Preset from '../assets/icons/Preset.svg';
import ProceduralEdit from '../assets/icons/ProceduralEdit.svg';
import Project from '../assets/icons/Project.svg';
import Publish from '../assets/icons/Publish.svg';
import Published from '../assets/icons/Published.svg';
import PushPin from '../assets/icons/PushPin.svg';
import QrCode from '../assets/icons/QrCode.svg';
import QuerySchema from '../assets/icons/QuerySchema.svg';
import QueryStats from '../assets/icons/QueryStats.svg';
import RadioChecked from '../assets/icons/RadioChecked.svg';
import RadioHalf from '../assets/icons/RadioHalf.svg';
import RadioUnchecked from '../assets/icons/RadioUnchecked.svg';
import Record from '../assets/icons/Record.svg';
import Refresh from '../assets/icons/Refresh.svg';
import Remove from '../assets/icons/Remove.svg';
import RemoveFilled from '../assets/icons/RemoveFilled.svg';
import Replay10 from '../assets/icons/Replay10.svg';
import Restart from '../assets/icons/Restart.svg';
import RightBlinkerHold from '../assets/icons/RightBlinkerHold.svg';
import Route from '../assets/icons/Route.svg';
import Run from '../assets/icons/Run.svg';
import Save from '../assets/icons/Save.svg';
import SaveAs from '../assets/icons/SaveAs.svg';
import Schedule from '../assets/icons/Schedule.svg';
import Sculpt from '../assets/icons/Sculpt.svg';
import Search from '../assets/icons/Search.svg';
import SearchGlobe from '../assets/icons/SearchGlobe.svg';
import Send from '../assets/icons/Send.svg';
import Sensors from '../assets/icons/Sensors.svg';
import Settings from '../assets/icons/Settings.svg';
import SettingsOutline from '../assets/icons/SettingsOutline.svg';
import SimianSensors from '../assets/icons/SimianSensors.svg';
import SingleSidedParallelogram from '../assets/icons/SingleSidedParallelogram.svg';
import SingleSidedTrapezoid from '../assets/icons/SingleSidedTrapezoid.svg';
import SitDown from '../assets/icons/SitDown.svg';
import Slice from '../assets/icons/Slice.svg';
import Smooth from '../assets/icons/Smooth.svg';
import Sort from '../assets/icons/Sort.svg';
import Speed from '../assets/icons/Speed.svg';
import Spline from '../assets/icons/Spline.svg';
import Split from '../assets/icons/Split.svg';
import Splitscreen from '../assets/icons/Splitscreen.svg';
import StandUp from '../assets/icons/StandUp.svg';
import StarFill from '../assets/icons/StarFill.svg';
import StarNoFill from '../assets/icons/StarNoFill.svg';
import Swap from '../assets/icons/Swap.svg';
import SwapHoriz from '../assets/icons/SwapHoriz.svg';
import SwitchAccount from '../assets/icons/SwitchAccount.svg';
import Sync from '../assets/icons/Sync.svg';
import Theaters from '../assets/icons/Theaters.svg';
import Timelapse from '../assets/icons/Timelapse.svg';
import Timeline from '../assets/icons/Timeline.svg';
import TouchApp from '../assets/icons/TouchApp.svg';
import Track from '../assets/icons/Track.svg';
import Traffic from '../assets/icons/Traffic.svg';
import TravelExplore from '../assets/icons/TravelExplore.svg';
import Truck from '../assets/icons/Truck.svg';
import Tune from '../assets/icons/Tune.svg';
import TypeBold from '../assets/icons/TypeBold.svg';
import TypeH1 from '../assets/icons/TypeH1.svg';
import TypeH2 from '../assets/icons/TypeH2.svg';
import TypeH3 from '../assets/icons/TypeH3.svg';
import TypeItalic from '../assets/icons/TypeItalic.svg';
import TypeParagraph from '../assets/icons/TypeParagraph.svg';
import TypeStrikethrough from '../assets/icons/TypeStrikethrough.svg';
import TypeUnderline from '../assets/icons/TypeUnderline.svg';
import UnfollowEgo from '../assets/icons/UnfollowEgo.svg';
import Updates from '../assets/icons/Updates.svg';
import Vehicle from '../assets/icons/Vehicle.svg';
import VerticalHierarchy from '../assets/icons/VerticalHierarchy.svg';
import Visibility from '../assets/icons/Visibility.svg';
import VisibilityOff from '../assets/icons/VisibilityOff.svg';
import Work from '../assets/icons/Work.svg';
import Zoom from '../assets/icons/Zoom.svg';
import ZoomIn from '../assets/icons/ZoomIn.svg';
import ZoomOut from '../assets/icons/ZoomOut.svg';

export const DragonFruitIcons = {
  ACCOUNT: Account,
  ACCOUNT_TREE: AccountTree,
  ACTIVITY: Activity,
  ACTOR_CHANNELS: ActorChannels,
  ACTORS: Actors,
  ADD: Add,
  ADD_LINE: AddLine,
  ADD_PARKING_BLOCK: AddParkingBlock,
  ADD_SIGN: AddSign,
  ADD_REGION: AddRegion,
  ADD_ROAD: AddRoad,
  ADD_PERSON: AddPerson,
  ANALYTICS: Analytics,
  ARCHIVE: Archive,
  ARROW_DROPDOWN: ArrowDropdown,
  ARROW_BACK: ArrowBack,
  ARROW_DOWN: ArrowDown,
  ARROW_FORWARD: ArrowForward,
  ARROW_RIGHT: ArrowRight,
  ARROW_UP: ArrowUp,
  ARROW_CLOCKWISE: ArrowClockwise,
  ARROW_COUNTER_CLOCKWISE: ArrowCounterClockwise,
  ASSESSMENT: Assessment,
  ASSIGNMENT: Assignment,
  ASSIGNMENT_LATE: AssignmentLate,
  ATTACH: Attach,
  AUTO_ARRANGE: AutoArrange,
  AUTO_GRAPH: AutoGraph,
  BALLOT: Ballot,
  BAR_CHART: BarChart,
  BATCH_COMPARISON: BatchComparison,
  BATCH_RUN: BatchRun,
  BOOKMARK: Bookmark,
  BUG: Bug,
  CAMERA: Camera,
  CAMERA_ROLL: CameraRoll,
  CAMERA_VIEW: CameraView,
  CANCEL: Cancel,
  CASINO: Casino,
  CHART: Chart,
  CHAT: Chat,
  CHECK: Check,
  CHECK_CIRCLE: CheckCircle,
  CHECK_CIRCLE_FILLED: CheckCircleFilled,
  CHEVRON_DOWN: ChevronDown,
  CHEVRON_LEFT: ChevronLeft,
  CHEVRON_RIGHT: ChevronRight,
  CHEVRON_UP: ChevronUp,
  CLOSE: Close,
  CLOSE_FULL: CloseFull,
  CLOUD: Cloud,
  CLOUD_DOWNLOAD: CloudDownload,
  CLOUD_UPLOAD: CloudUpload,
  CODE: Code,
  COLLECTIONS_BOOKMARK: CollectionsBookmark,
  COLLECTIONS_IMAGE: CollectionsImage,
  COMMENT: Comment,
  CONFIG: Config,
  CONNECT: Connect,
  COMPARE: Compare,
  COPILOT: Copilot,
  COPY: Copy,
  CREATE: Create,
  CYCLIST: Cyclist,
  DASHBOARD: Dashboard,
  DASHBOARD_CUSTOMIZE: DashboardCustomize,
  DASHBOARD_CONFIGURATION: DashboardConfiguration,
  DATA_OBJECTS: DataObjects,
  DATE: Date,
  DEFAULT: Default,
  DELETE: Delete,
  DESCRIPTION: Description,
  DIRECT_SELECT: DirectSelect,
  DOUBLE_SIDED_PARALLELOGRAM: DoubleSidedParallelogram,
  DOUBLE_SIDED_TRAPEZOID: DoubleSidedTrapezoid,
  DOWNLOAD: Download,
  DRAG: Drag,
  DRAWINGS: Drawings,
  DRIVE_FOLDER_UPLOAD: DriveFolderUpload,
  EGO: Ego,
  ENVIRONMENTS: Environments,
  ERROR: Error,
  ERROR_FILLED: ErrorFilled,
  EXIT_FULLSCREEN: ExitFullscreen,
  EXPAND: Expand,
  EXPLORE: Explore,
  FACT_CHECK: FactCheck,
  FAVORITE: Favorite,
  FEEDBACK: Feedback,
  FILE_UPLOAD: FileUpload,
  FILTER_ALT: FilterAlt,
  FILTER_LIST: FilterList,
  FIX: Fix,
  FLAG: Flag,
  FLIP_TO_FRONT: FlipToFront,
  FLOAT_TILE: FloatTile,
  FOLDER: Folder,
  FOLLOW_EGO: FollowEgo,
  FULLSCREEN: Fullscreen,
  GLOBE: Globe,
  GRID_VIEW: GridView,
  GUI_EDITOR: GuiEditor,
  HELP: Help,
  HELP_OUTLINE: HelpOutline,
  HIGHLIGHTS: Highlights,
  HIGHLIGHT_OFF: HighlightOff,
  HISTORY: History,
  HIVE: Hive,
  HOME: Home,
  HORIZONTAL_HIERARCHY: HorizontalHierarchy,
  IMAGE: Image,
  INBOX: Inbox,
  INCLUDES: Includes,
  INFO: Info,
  INFO_OUTLINE: InfoOutline,
  INVENTORY: Inventory,
  LABEL: Label,
  LAN: Lan,
  LAYERS: Layers,
  LAYOUT: Layout,
  LIBRARY: Library,
  LINK: Link,
  LINK_OFF: LinkOff,
  LIST: List,
  LIST_OL: ListOL,
  LIST_UL: ListUL,
  LOADING: Loading,
  LOGIN: Login,
  LOGOUT: Logout,
  LOGS: Logs,
  LOCK: Lock,
  MAP: Map,
  MATERIALS: Materials,
  MATH: MathIcon,
  MEASURE: Measure,
  MERGE_TYPE: MergeType,
  MORE_HORIZ: MoreHoriz,
  MORE_VERT: MoreVert,
  NEW_FOLDER: NewFolder,
  NOTIFICATIONS: Notifications,
  OBSERVERS: Observers,
  OFFLINE_BOLT: OfflineBolt,
  OPENIN_NEW: OpenInNew,
  OPEN_TABS: OpenTabs,
  OVERLAY: Overlay,
  OPEN_FULL: OpenFull,
  PALETTE: Palette,
  PAN: Pan,
  PARAMETERS: Parameters,
  PEDESTRIAN: Pedestrian,
  PEN: Pen,
  PENDING: Pending,
  PENDING_OUTLINE: PendingOutline,
  PEOPLE: People,
  PERSON: Person,
  POLYGON: Polygon,
  POLYLINE: Polyline,
  PRESET: Preset,
  PROJECT: Project,
  PROCEDURAL_EDIT: ProceduralEdit,
  PLAY: Play,
  PLAYLIST: Playlist,
  PLAYLIST_ADD: PlaylistAdd,
  PUBLISH: Publish,
  PUBLISHED: Published,
  PUSH_PIN: PushPin,
  QR_CODE: QrCode,
  QUERY_SCHEMA: QuerySchema,
  QUERY_STATS: QueryStats,
  RADIO_CHECKED: RadioChecked,
  RADIO_HALF: RadioHalf,
  RADIO_UNCHECKED: RadioUnchecked,
  RECORD: Record,
  REFRESH: Refresh,
  REMOVE: Remove,
  REMOVE_FILLED: RemoveFilled,
  REPLAY_10: Replay10,
  RESTART: Restart,
  ROUTE: Route,
  RUN: Run,
  SAVE: Save,
  SAVE_AS: SaveAs,
  SCHEDULE: Schedule,
  SCULPT: Sculpt,
  SEARCH: Search,
  SEARCH_GLOBE: SearchGlobe,
  SEND: Send,
  SENSORS: Sensors,
  SETTINGS: Settings,
  SETTINGS_OUTLINE: SettingsOutline,
  SIMIAN_SENSORS: SimianSensors,
  SINGLE_SIDED_PARALLELOGRAM: SingleSidedParallelogram,
  SINGLE_SIDED_TRAPEZOID: SingleSidedTrapezoid,
  SLICE: Slice,
  SMOOTH: Smooth,
  SORT: Sort,
  SPEED: Speed,
  CONTAINED_SPLINE: ContainedSpline,
  SPLINE: Spline,
  SPLIT: Split,
  SPLITSCREEN: Splitscreen,
  SYNC: Sync,
  STAR_FILL: StarFill,
  STAR_NO_FILL: StarNoFill,
  SWAP: Swap,
  SWAP_HORIZ: SwapHoriz,
  SWITCH_ACCOUNT: SwitchAccount,
  THEATERS: Theaters,
  THREE_D: ThreeD,
  TIMELAPSE: Timelapse,
  TIMELINE: Timeline,
  TOUCH_APP: TouchApp,
  TYPE_BOLD: TypeBold,
  TYPE_H1: TypeH1,
  TYPE_H2: TypeH2,
  TYPE_H3: TypeH3,
  TYPE_ITALIC: TypeItalic,
  TYPE_PARAGRAPH: TypeParagraph,
  TYPE_STRIKETHROUGH: TypeStrikethrough,
  TYPE_UNDERLINE: TypeUnderline,
  TRACK: Track,
  TRAFFIC: Traffic,
  TRAVEL_EXPLORE: TravelExplore,
  TRUCK: Truck,
  TUNE: Tune,
  TWO_D: TwoD,
  UNFOLLOW_EGO: UnfollowEgo,
  UPDATES: Updates,
  VEHICLE: Vehicle,
  VERTICAL_HIERARCHY: VerticalHierarchy,
  VISIBILITY: Visibility,
  VISIBILITY_OFF: VisibilityOff,
  WORK: Work,
  ZOOM: Zoom,
  ZOOM_IN: ZoomIn,
  ZOOM_OUT: ZoomOut,
  LIKE: Like,
  BLINKER_STALK_IDLE: BlinkerStalkIdle,
  LEFT_BLINKER_HOLD: LeftBlinkerHold,
  OPEN_DOOR: OpenDoor,
  POWER_BUTTON: PowerButton,
  RIGHT_BLINKER_HOLD: RightBlinkerHold,
  SIT_DOWN: SitDown,
  STAND_UP: StandUp,
} satisfies Record<string, string>;

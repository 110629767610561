import React, { useLayoutEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import classNames from 'classnames';

import { DRAGONFRUIT_PORTAL_ROOT_ID } from '../../portal';

export interface InternalCursorTooltipPlacementProps {
  tooltip: JSX.Element;
  /**
   * Whether the content wrapper should fill its parent. This can be used in the case where e.g.
   * you are wrapping a button that you want to fill its parent.
   */
  shouldContentWrapperFillContainer?: boolean;
  /**
   * When false, the tooltip will not be shown, regardless of mouse positioning.
   * When undefined or true, the tooltip will behave as normal, displaying on the mouse
   * cursor when hovering.
   */
  overrideShow?: boolean;
  maxWidth: number;
  children?: React.ReactNode;
}

const WINDOW_PADDING = 24;
export const TOOLTIP_MAX_WIDTH = 300;
const OPEN_LEFT_THRESHOLD = 350;
const X_TRANSLATE = 'translateX(-100%)';
const Y_TRANSLATE = 'translateY(-100%)';

const InternalCursorTooltipPlacement: React.FC<InternalCursorTooltipPlacementProps> = (props) => {
  const [x, setX] = useState<number | undefined>(undefined);
  const [y, setY] = useState<number | undefined>(undefined);

  const ref = useRef<HTMLDivElement>(null);

  const onMouseMove = (ev: React.MouseEvent): void => {
    if (overrideShow === false) {
      // Do not show the Tooltip is overrideShow == false.
      return;
    }
    setX(ev.pageX);
    setY(ev.pageY);
  };

  const onMouseLeave = (): void => {
    setX(undefined);
    setY(undefined);
  };

  const { children, tooltip, shouldContentWrapperFillContainer, overrideShow, maxWidth } = props;

  const top = y !== undefined ? y - 2 : undefined;
  const left = x !== undefined ? x + 2 : undefined;

  useLayoutEffect(() => {
    if (ref.current && top !== undefined && left !== undefined) {
      let transform = '';
      if (ref.current.offsetHeight <= top - WINDOW_PADDING) {
        transform = Y_TRANSLATE;
      }

      if (ref.current.offsetWidth + left > window.innerWidth - WINDOW_PADDING) {
        transform += X_TRANSLATE;
      }

      ref.current.style.transform = transform;
    }
  });

  return (
    <div
      className={classNames('dragonfruit-tooltip-wrapper', {
        'should-fill-container': shouldContentWrapperFillContainer,
      })}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      {children}
      {x !== undefined && top !== undefined && left !== undefined && (
        <Portal node={document.getElementById(DRAGONFRUIT_PORTAL_ROOT_ID)}>
          <div
            style={{
              left: left - maxWidth,
              top,
              width: maxWidth * 2,
            }}
            className="dragonfruit-tooltip-placement cursor"
          >
            <div
              className="tooltip-wrapper"
              style={{
                transform: x > window.innerWidth - OPEN_LEFT_THRESHOLD ? X_TRANSLATE : undefined,
                left: maxWidth,
                top: 0,
              }}
              ref={ref}
            >
              {tooltip}
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default InternalCursorTooltipPlacement;

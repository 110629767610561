import React from 'react';
import classNames from 'classnames';

import './loading-spinner.scss';

const DEFAULT_SPINNER_SIZE_PX = 24;

type LoadingSpinnerAppearances = 'primary';
interface LoadingSpinnerProps {
  /**
   * Size of the loading spinner in pixels
   */
  size?: number;
  /**
   * Optionally change the color of the LoadingSpinner to
   * one of our pre-defined colors.
   */
  appearance?: LoadingSpinnerAppearances;
  /**
   * Whether to fill container width. The height will resize to maintain the loading spinner
   * as a circle.
   */
  shouldFillContainerWidth?: boolean;
  /**
   * Whether to fill container height. The width will resize to maintain the loading spinner
   * as a circle.
   */
  shouldFillContainerHeight?: boolean;
  /**
   * If true, the loading spinner will be centered horizontally within its parent.
   */
  shouldCenterHorizontally?: boolean;
  /**
   * If true, the loading spinner will be centered vertically within its parent.
   */
  shouldCenterVertically?: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = DEFAULT_SPINNER_SIZE_PX,
  appearance,
  shouldFillContainerHeight,
  shouldFillContainerWidth,
  shouldCenterHorizontally,
  shouldCenterVertically,
}) => {
  return (
    <div
      className={classNames('loading-spinner-wrapper', {
        'centered-loading-spinner-horizontally': shouldCenterHorizontally,
        'centered-loading-spinner-vertically': shouldCenterVertically,
        'fill-container-width': shouldFillContainerWidth,
        'fill-container-height': shouldFillContainerHeight,
      })}
    >
      <div
        className={classNames('dragonfruit-loading-spinner', {
          'dragonfruit-loading-spinner--primary': appearance === 'primary',
        })}
        data-testid="loading-spinner"
        style={{
          width: size,
          height: size,
        }}
      />
    </div>
  );
};

export default LoadingSpinner;

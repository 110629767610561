import React from 'react';

import type { TooltipProps } from './Tooltip';
import Tooltip from './Tooltip';

export type ConditionalTooltipProps = TooltipProps & {
  showTooltip?: boolean;
};

const ConditionalTooltip: React.FC<ConditionalTooltipProps> = (props) => {
  const { children, showTooltip, ...otherProps } = props;
  return showTooltip ? <Tooltip {...otherProps}>{children}</Tooltip> : <>{children}</>;
};

export default ConditionalTooltip;

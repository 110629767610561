import React from 'react';
import { Link } from 'react-router-dom';
import { isRelativeUrl } from '@applied/utils/urls';
import classNames from 'classnames';

import './conditional-link.scss';

interface ConditionalLinkProps {
  /**
   * Relative links (determined by whether the input starts with "/") will open in the current tab.
   * Absolute links will open in a new tab by default.
   */
  link: string | undefined;
  /**
   * If disabled, this component will render its children without a link.
   */
  disabled?: boolean;
  shouldFillContainer?: boolean;
  children?: React.ReactNode;
}

const ConditionalLink: React.FC<ConditionalLinkProps> = (props) => {
  const { link, disabled, shouldFillContainer, children } = props;

  if (!disabled && link) {
    if (isRelativeUrl(link)) {
      return (
        <Link
          to={link}
          className={classNames('conditional-link-style text-style-link', {
            'should-fill-container': shouldFillContainer,
          })}
        >
          {children}
        </Link>
      );
    }
    return (
      <a
        href={link}
        className={classNames('conditional-link-style text-style-link', {
          'should-fill-container': shouldFillContainer,
        })}
        target="_blank"
        rel="noreferrer noopener"
      >
        {children}
      </a>
    );
  }
  return <>{children}</>;
};

export default ConditionalLink;

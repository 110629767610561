import React, { useEffect, useRef, useState } from 'react';

import type { ButtonProps } from '../ActionButton';
import ActionButton from '../ActionButton';
import Tooltip from '../Tooltip/Tooltip';

type OmittedButtonWithFeedbackProps =
  // This component will handle the selected state of the button, so we disallow overriding.
  | 'selected'
  // Omitting onFileChange because if not, then the file selector and tooltip will show up
  // around the same time, which is strange UX.
  | 'onFileChange';

export interface ButtonWithFeedbackProps extends Omit<ButtonProps, OmittedButtonWithFeedbackProps> {
  /**
   * For 1 second after the button is clicked, the button will be disabled and
   * display this text as a tooltip.
   */
  feedbackText: string;
}

const ButtonWithFeedback: React.FC<ButtonWithFeedbackProps> = (props) => {
  const { onClick, feedbackText, children, ...otherProps } = props;

  const [showFeedback, setShowFeedback] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Tooltip
      tooltip={feedbackText}
      side="bottom"
      placementType="fixed"
      alignment="center"
      overrideShow={showFeedback}
    >
      <ActionButton
        onClick={(event): void => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          setShowFeedback(true);
          timeoutRef.current = setTimeout(() => {
            setShowFeedback(false);
          }, 1000);
          onClick?.(event);
        }}
        selected={showFeedback}
        {...otherProps}
      >
        {children}
      </ActionButton>
    </Tooltip>
  );
};

export default ButtonWithFeedback;
